import React from "react"
import AutoSizer from "react-virtualized-auto-sizer"

export default function withAutoSizer(ListComponent) {
	return function AutoSizedList(props) {
		return (
			<AutoSizer>
				{({ height, width }) => <ListComponent {...props} height={height} width={width} />}
			</AutoSizer>
		)
	}
}
