import React from "react"
import { GridOverlay } from "@mui/x-data-grid"
import WarningIcon from "@material-ui/icons/Warning"

import { DATA_GRID } from "../_enums/dataGrid"

export function CustomNoRowsOverlay() {
	return (
		<GridOverlay>
			<div>{DATA_GRID.TEXTS.OVERLAY.NO_ROWS}</div>
		</GridOverlay>
	)
}

export function CustomNoTechnologyOverlay() {
	return (
		<GridOverlay>
			<div className="overlay-modal-no-technology">
				<div className="row" style={{ marginLeft: "10px" }}>
					<div className="col">
						<div className="row overlay-modal-no-technology-title">
							<WarningIcon style={{ marginRight: "10px" }} />
							{DATA_GRID.TEXTS.OVERLAY.NO_TECHNOLOGY_TITLE}
						</div>
					</div>
				</div>
				<div className="row" style={{ marginLeft: "10px" }}>
					<div className="col">
						<div className="row overlay-modal-no-technology-text">
							{`${DATA_GRID.TEXTS.OVERLAY.NO_TECHNOLOGY_TEXT} `}
						</div>
					</div>
				</div>
			</div>
		</GridOverlay>
	)
}
