import { useEffect, useState } from "react"
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore"
import { firestore as db } from "../../firebase"

export const useQueryDb = ({ user, messagingSwitch, collectionPath, queryParams }) => {
	const [data, setData] = useState([])

	useEffect(() => {
		if (!messagingSwitch || !user || !collectionPath) return

		const dataRef = collection(db, collectionPath)

		// Build Firestore query dynamically
		let queryConstraints = []

		if (queryParams?.where) {
			queryConstraints = [
				...queryConstraints,
				...queryParams.where.map(condition => where(...condition))
			]
		}

		if (queryParams?.orderBy) {
			queryConstraints = [
				...queryConstraints,
				...queryParams.orderBy.map(condition => orderBy(...condition))
			]
		}

		const q = query(dataRef, ...queryConstraints)

		// Subscribe to the query's real-time updates
		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))
			setData(data)
		})

		// Clean up subscription on unmount or parameter change
		return () => unsubscribe()
	}, [db, messagingSwitch, user, collectionPath])

	return data
}
