import React from "react"
import { FixedSizeList as List } from "react-window"

const listRowHeight = 43

export default function withReactWindowList(ListRowComponent) {
	return function ReactWindowList({ height, width, listItems, activeItem, onItemClick }) {
		return (
			<List height={height} width={width} itemCount={listItems.length} itemSize={listRowHeight}>
				{({ index, style }) => {
					const item = listItems[index]
					const isActive = activeItem === item

					return (
						<div style={style} onClick={() => onItemClick(item)}>
							<ListRowComponent listItem={item} isActive={isActive} />
						</div>
					)
				}}
			</List>
		)
	}
}
