import React from "react"
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Brand } from "../components/brand/Brand"
import Drawer from "../../_partials/Drawer"
import { SIDEBAR } from "../../_enums/sidebar"

const MainDrawer = ({
	selectedMainOption,
	setSelectedMainOption,
	setSelectedNestedOption,
	setCurrentNestedOptions,
	setNestedDrawerOpen,
	mainDrawerOpened,
	setMainDrawerOpened,
	options,
	hideDrawer,
	handleHideDrawer,
	matches,
	history,
	classes
}) => {
	const handleMainOptionClick = option => {
		setSelectedMainOption(option.label.redirectTo)
		if (option.nestedOptions) {
			setSelectedNestedOption(option)
			setCurrentNestedOptions(option.nestedOptions)
			setNestedDrawerOpen(true)
		} else if (option.label.redirectTo) {
			history.push(option.label.redirectTo)
			!matches && handleHideDrawer()
		}
	}

	const handleOpenDrawer = () => {
		setMainDrawerOpened(prev => !prev)
	}

	return (
		<Drawer
			variant={SIDEBAR.STYLES.DRAWER.VARIANT_PERSISTENT}
			open={hideDrawer}
			classes={{
				paper: matches && mainDrawerOpened ? classes.mainDrawer : classes.mainDrawerClosed
			}}
		>
			<Brand
				handleOpenDrawer={handleOpenDrawer}
				handleHideDrawer={handleHideDrawer}
				mainDrawerOpened={mainDrawerOpened}
				matches={matches}
			/>

			<List>
				{options.map((option, index) => (
					<ListItem
						button
						style={{ maxHeight: SIDEBAR.STYLES.MAIN_LIST_ITEM.MAX_HEIGHT }}
						key={index}
						onClick={() => handleMainOptionClick(option)}
						className={
							selectedMainOption === option.label.redirectTo
								? `${classes.listItem} ${classes.selectedListItem}`
								: classes.listItem
						}
					>
						<ListItemIcon
							style={{
								placeContent: SIDEBAR.STYLES.LIST_ITEM.ICON.PLACE_CONTENT_CENTER,
								marginTop: SIDEBAR.STYLES.LIST_ITEM.ICON.MARGIN_TOP
							}}
							className={
								selectedMainOption === option.label.redirectTo
									? `${classes.selectedListIcon} ${classes.drawerIconSize}`
									: `${classes.drawerIconSize}`
							}
						>
							{option.label.icon}
						</ListItemIcon>
						<ListItemText
							style={{
								textOverflow: SIDEBAR.STYLES.MAIN_LIST_ITEM.TEXT.TEXT_OVERFLOW_ELLIPSIS,
								overflow: SIDEBAR.STYLES.MAIN_LIST_ITEM.TEXT.OVERFLOW_HIDDEN,
								whiteSpace: SIDEBAR.STYLES.MAIN_LIST_ITEM.TEXT.WHITE_SPACE_NOWRAP
							}}
							primary={option.label.title}
						/>
						<ListItemIcon
							style={{
								placeContent: SIDEBAR.STYLES.LIST_ITEM.ICON.PLACE_CONTENT_END
							}}
							className={
								selectedMainOption === option.label.redirectTo &&
								`${classes.selectedListIcon}`
							}
						>
							<ArrowForwardIosIcon />
						</ListItemIcon>
					</ListItem>
				))}
			</List>
		</Drawer>
	)
}

export default MainDrawer
