export const OPERATIONS_HEADER = {
	TEXTS: {
		TITLE: "Manage Infrastructure"
	},
	STYLES: {
		TITLE: {
			COLOR: "#4A4A68"
		}
	},
	SVG: {
		PATH: "/media/svg/icons/General/manage-infra-icon.svg"
	},
	BUTTONS: {
		ARRAY_OF_BUTTONS: [
			{
				id: "network",
				icon: "/media/svg/icons/General/network-manage-infra-icon.svg",
				label: "Network"
			},
			{
				id: "sensors",
				icon: "/media/svg/icons/General/sensors-manage-infra-icon.svg",
				label: "Sensors"
			}
		]
	}
}
