export const SIDEBAR = {
	STYLES: {
		DRAWER: {
			ANCHOR_LEFT: "left",
			VARIANT_PERSISTENT: "persistent"
		},
		LIST_ITEM: {
			ICON: {
				PLACE_CONTENT_CENTER: "center",
				PLACE_CONTENT_END: "end",
				MARGIN_TOP: "5px"
			}
		},
		MAIN_LIST_ITEM: {
			MAX_HEIGHT: "42px",
			TEXT: {
				TEXT_OVERFLOW_ELLIPSIS: "ellipsis",
				OVERFLOW_HIDDEN: "hidden",
				WHITE_SPACE_NOWRAP: "nowrap"
			}
		},
		NESTED_LIST_ITEM: {
			DISPLAY_LIST_ITEM: "list-item",
			LIST_STYLE_TYPE_DISC: "disc",
			MARGIN_LEFT: "30px"
		},
		NESTED_LIST_OPTION: {
			RENDER_COMPONENT_AS_DIV: "div"
		},
		ICONS: {
			ALIGN_SELF_END: "end",
			FONT_SIZE_LARGE: "large",
			SIDEBAR_COLLAPSE_ICON: {
				// after Feature/CKC-2925 PR is approved, change this to use dynamic icon render function
				PATHS: [
					"M10.5893 5.5886C10.9147 5.26317 10.9147 4.73553 10.5893 4.41009C10.2638 4.08466 9.73618 4.08466 9.41074 4.41009L4.41074 9.41009C4.09526 9.72558 4.08422 10.2336 4.3857 10.5625L8.96903 15.5625C9.28003 15.9017 9.80717 15.9246 10.1464 15.6136C10.4857 15.3027 10.5086 14.7755 10.1976 14.4362L6.15344 10.0244L10.5893 5.5886Z",
					"M16.4226 5.5886C16.748 5.26317 16.748 4.73553 16.4226 4.41009C16.0972 4.08466 15.5695 4.08466 15.2441 4.41009L10.2441 9.41009C9.9286 9.72558 9.91756 10.2336 10.219 10.5625L14.8024 15.5625C15.1134 15.9017 15.6405 15.9246 15.9798 15.6136C16.319 15.3027 16.342 14.7755 16.031 14.4362L11.9868 10.0244L16.4226 5.5886Z"
				]
			},
			PRIMARY_DARK_FILL: "#1C1C3B"
		},
		COLLAPSE: {
			TIMEOUT_AUTO: "auto"
		}
	}
}
