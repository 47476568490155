const MAIN_ROUTE = "/operations/inventory"

const EDIT_INVENTORY_MODAL = "editInventoryModal"
const UNASSIGN_INVENTORY_MODAL = "unassignInventoryModal"
const DELETE_INVENTORY_NODES_MODAL = "deleteInventoryNodesModal"
const ASSIGN_INVENTORY_MODAL = "assignInventoryModal"
const PRINT_NODE_LABEL_MODAL = "printNodeLabelModal"
const EDIT_INVENTORY_MODELS_MODAL = "editInventoryModelsModal"
const DELETE_INVENTORY_MODELS_MODAL = "deleteInventoryModelsModal"
const DELETE_INVENTORY_FIRMWARES_MODAL = "deleteInventoryFirmwaresModal"

export const MODALS = {
	EDIT_INVENTORY_MODAL,
	UNASSIGN_INVENTORY_MODAL,
	DELETE_INVENTORY_NODES_MODAL,
	ASSIGN_INVENTORY_MODAL,
	PRINT_NODE_LABEL_MODAL,
	EDIT_INVENTORY_MODELS_MODAL,
	DELETE_INVENTORY_MODELS_MODAL,
	DELETE_INVENTORY_FIRMWARES_MODAL
}

export const ROUTES = {
	MAIN_ROUTE,
	NODES_ROUTE: `${MAIN_ROUTE}/nodes`,
	MODELS_ROUTE: `${MAIN_ROUTE}/models`,
	FIRMWARES_ROUTE: `${MAIN_ROUTE}/firmwares`,
	EDITE_INVENTORY_NODES: `${MAIN_ROUTE}/editInventoryNodes`,
	UNASSIGN_INVENTORY_NODES: `${MAIN_ROUTE}/openUnassignInventoryNodes`,
	DELETE_INVENTORY_NODES: `${MAIN_ROUTE}/openDeleteInventoryNodesModal`,
	ASSIGN_INVENTORY_NODES: `${MAIN_ROUTE}/openAssignInventoryNodes`,
	PRINT_NODE_LABEL: `${MAIN_ROUTE}/openPrintNodeLabelModal`,
	EDIT_INVENTORY_MODELS: `${MAIN_ROUTE}/openEditInventoryModelsModal`,
	DELETE_INVENTORY_MODELS: `${MAIN_ROUTE}/openDeleteInventoryModelsModal`,
	DELETE_INVENTORY_FIRMWARES: `${MAIN_ROUTE}/openDeleteInventoryFirmwaresModal`
}
