import React from "react"
import { storage } from "../../firebase"

export async function useFetchDownloadUrl(path) {
	try {
		const url = await storage.ref(path).getDownloadURL()
		return url
	} catch (error) {
		console.error(error)
		throw error
	}
}
