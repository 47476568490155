import * as requestFromServer from "./peopleCrud"
import { reportingSlice, callTypes } from "../reportingSlice"
// import * as ActionHelpers from "./ActionHelpers"
import * as ActionHelpersNew from "./ActionHelpersNew"

const { actions } = reportingSlice

// Main Promise.all function for People Summary
export const fetchPeopleSummary = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllPeople(queryParams, dispatch),
		fetchReportAreas(queryParams, dispatch),
		fetchReportFloorsPeople(queryParams, dispatch),
		fetchLocationReportAreas(queryParams, dispatch),
		fetchReportFloorsLocation(queryParams, dispatch),
		fetchReportBuildingsLocation(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleSummary function : ", error)
		error.clientMessage = "Can't fetch People Summary"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Main Promise.all function for People Timesheets
export const fetchPeopleTimesheetFetchAllPeople = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllPeople(queryParams, dispatch)
		// fetchAllPeopleToTimesheets(queryParams, dispatch),
		// fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}
export const fetchPeopleTimesheetFetchAllPeopleToTimesheets = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		// fetchAllPeople(queryParams, dispatch),
		fetchAllPeopleToTimesheets(queryParams, dispatch)
		// fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}
export const fetchPeopleTimesheetFetchSinglePeopleToTimesheets = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		// fetchAllPeople(queryParams, dispatch),
		// fetchAllPeopleToTimesheets(queryParams, dispatch),
		fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Used in charts: Top 6 attendance tracking, Attendance by role
function fetchAllPeople(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allPeopleDistribution }))
	if (!queryParams) {
		dispatch(actions.allPeopleDistributionFetched(null))
		return
	}

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response.json())
		.then(result => {
			if (!result || result?.length === 0) {
				dispatch(actions.allPeopleDistributionFetched(null))
				return
			}

			// Clone data before processing
			const clonedData = JSON.parse(JSON.stringify(result))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult1({
				result: clonedData,
				queryParams
			})

			// Ensure newData is an array before proceeding
			const newData = Array.isArray(data)
				? ActionHelpersNew.parsingAllPeopleDataHelper(data)
				: []

			const finalData =
				newData.length > 0
					? ActionHelpersNew.handleAttendenceByRole({
							result: newData,
							queryParams
					  })
					: null

			if (finalData) {
				dispatch(actions.allPeopleDistributionFetched(finalData))
			}
		})
		.catch(error => {
			dispatch(actions.allPeopleDistributionFetched(null))
			console.error("Fetch all People distribution error: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Total time spent per Area
export function fetchReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaAvgTimeSpent }))

	if (!queryParams) dispatch(actions.areaAvgTimeSpentFetched(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.areaAvgTimeSpentFetched(null))

			const data = ActionHelpersNew.avgTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.areaAvgTimeSpentFetched(data))
		})

		.catch(error => {
			dispatch(actions.areaAvgTimeSpentFetched(null))
			console.log("Fetch avg time spent report Areas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas"
			dispatch(actions.catchError({ error, callType: callTypes.areaAvgTimeSpent }))
		})
}

// // Used in charts: People Distribution per Area
// export function fetchReportAreas(queryParams, dispatch) {
// 	dispatch(actions.startCall({ callType: callTypes.areaAvgTimeSpent }))

// 	if (!queryParams) dispatch(actions.areaAvgTimeSpentFetched(null))
// 	return requestFromServer
// 		.getAvgTimeSpent(queryParams)
// 		.then(response => response && response.json())
// 		.then(result => {
// 			if (!result) dispatch(actions.areaAvgTimeSpentFetched(null))

// 			console.log("🚀  result:", result)

// 			const data = ActionHelpersNew.avgTimeSpentReportAreas({
// 				result,
// 				queryParams
// 			})

// 			data && dispatch(actions.areaAvgTimeSpentFetched(data))
// 		})

// 		.catch(error => {
// 			console.log("Fetch avg time spent report Areas: ", error)
// 			error.clientMessage = "Can't fetch avg time spent report Areas"
// 			dispatch(actions.catchError({ error, callType: callTypes.areaAvgTimeSpent }))
// 		})
// }

// Used in charts: Total time per Floor
export function fetchReportFloorsPeople(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleDistribution }))

	if (!queryParams) dispatch(actions.peopleDistributionFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.peopleDistributionFetched(null))

			const data = ActionHelpersNew.parseReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.peopleDistributionFetched(data))
		})

		.catch(error => {
			dispatch(actions.peopleDistributionFetched(null))
			console.log("fetchReportFloorsPeople function error: ", error)
			error.clientMessage = "Can't fetch Report Floors People"
			dispatch(actions.catchError({ error, callType: callTypes.peopleDistribution }))
		})
}

// Used in charts: Location Summary Areas
export function fetchLocationReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaLocationTimeSpent }))

	if (!queryParams) dispatch(actions.fetchLocationReportAreasFetched(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.fetchLocationReportAreasFetched(null))

			const data = ActionHelpersNew.locationTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.fetchLocationReportAreasFetched(data))
		})

		.catch(error => {
			dispatch(actions.fetchLocationReportAreasFetched(null))
			console.log("fetchLocationReportAreas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas Location"
			dispatch(actions.catchError({ error, callType: callTypes.areaLocationTimeSpent }))
		})
}

// Used in charts: Location Summary Floors
export function fetchReportFloorsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleDistributionLocation }))

	if (!queryParams) dispatch(actions.peopleDistributionLocationFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0)
				dispatch(actions.peopleDistributionLocationFetched(null))

			const data = ActionHelpersNew.parseLocationReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.peopleDistributionLocationFetched(data))
		})

		.catch(error => {
			dispatch(actions.peopleDistributionLocationFetched(null))
			console.log("fetch Report Floors Location function error: ", error)
			error.clientMessage = "Can't fetch Report Floors Location"
			dispatch(actions.catchError({ error, callType: callTypes.peopleDistributionLocation }))
		})
}

// Used in charts: Location Summary Buildings
export function fetchReportBuildingsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.peopleBuildings }))

	if (!queryParams) dispatch(actions.peopleBuildingsFetched(null))
	return requestFromServer
		.getPeopleDistributionBuildings(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.peopleBuildingsFetched(null))

			const data = ActionHelpersNew.parseLocationReportBuildings({
				result,
				queryParams
			})

			data && dispatch(actions.peopleBuildingsFetched(data))
		})

		.catch(error => {
			dispatch(actions.peopleBuildingsFetched(null))
			return requestFromServer
			console.log("fetch Report Buildings Location function error: ", error)
			error.clientMessage = "Can't fetch Report Buildings Location"
			dispatch(actions.catchError({ error, callType: callTypes.peopleBuildings }))
		})
}

// Used in charts: Total contracted workers per role timesheet
function fetchAllPeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allPeopleTimesheet }))
	if (!queryParams) dispatch(actions.allPeopleToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => {
			return response && response.json()
		})
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.allPeopleToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult1({
				result,
				queryParams
			})

			const newData = ActionHelpersNew.parsingAllPeopleDataHelper(data)

			newData && dispatch(actions.allPeopleToTimesheetsFetched(newData))
		})

		.catch(error => {
			dispatch(actions.allPeopleToTimesheetsFetched(null))
			console.log("Fetch all People distribution[#2]: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Daily contracted workers timesheet
function fetchSinglePeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.singlePeopleTimesheet }))
	if (!queryParams) dispatch(actions.singlePeopleToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			// console.log("🚀 ~ fetchSinglePeopleToTimesheets ~ result:", result)

			if (!result || result.length === 0) dispatch(actions.singlePeopleToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllPeopleDistributionResult({
				result,
				queryParams
			})
			// console.log("🚀  data:", data)

			data && dispatch(actions.singlePeopleToTimesheetsFetched(data))
		})

		.catch(error => {
			dispatch(actions.singlePeopleToTimesheetsFetched(null))
			console.log("Fetch all People distribution[#3]: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
