import React from "react"
import { collection, query, where, orderBy, getDocs } from "firebase/firestore"
import { firestore as db } from "../../firebase"

export const useFirestoreGetDocs = async ({ collectionPath, queryParams }) => {
	if (!collectionPath) return

	const dataRef = collection(db, collectionPath)

	let queryConstraints = []

	if (queryParams?.where) {
		queryConstraints = [
			...queryConstraints,
			...queryParams.where.map(condition => where(...condition))
		]
	}

	if (queryParams?.orderBy) {
		queryConstraints = [
			...queryConstraints,
			...queryParams.orderBy.map(condition => orderBy(...condition))
		]
	}

	const q = query(dataRef, ...queryConstraints)

	const queryResult = await getDocs(q)
	return queryResult
}
export default useFirestoreGetDocs
