import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import SelectPeopleTab from "./SelectPeopleTab/SelectPeopleTab"
import ConfirmTab from "./ConfirmTab.js/ConfirmTab"
import SendMessage from "./SendMessage/SendMessage"
import { useUIContext } from "../UIContext"
import TabMenu from "../../../_partials/tabs/TabMenu"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		borderRadius: "4px",
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
		backgroundColor: "#FFFFFF"
	},

	tab: {
		boxShadow: "none",
		border: "none",
		backgroundColor: "#FFFFFF",

		"& .MuiTab-root": {
			textAlign: "center",
			fontFamily: "Poppins",
			fontSize: "15px",
			fontStyle: "normal",
			color: "#B8BCCA",
			fontWeight: "500",
			lineHeight: "160%",
			textTransform: "capitalize",
			padding: "1.5rem"
		},

		"& .Mui-selected": {
			color: "#4A4A68",
			fontWeight: "600"
		}
	}
}))

export function NewGroupModalTabs({
	onHide,
	initialValues,
	groupId,
	allGroups,
	allVendors,
	allPeople
}) {
	const classes = useStyles()

	// useUIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			setSitesValue: UIContext.setSitesValue,
			setGroupName: UIContext.setGroupName,
			setSelectedValues: UIContext.setSelectedValues,
			setGroupId: UIContext.setGroupId,
			members: UIContext.members,
			setMembers: UIContext.setMembers
		}
	}, [UIContext])

	useEffect(() => {
		if (!groupId || !initialValues) return

		UIProps.setGroupName(initialValues?.name)
		UIProps.setGroupId(groupId)
		UIProps.setMembers(initialValues?.members)

		allGroups &&
			allGroups.length > 0 &&
			allGroups.map(group => {
				if (group.id === groupId) {
					if (group.sites && group.sites.length > 0) UIProps.setSitesValue(group.sites)
				}
			})
	}, [groupId, initialValues])

	useEffect(() => {
		if (!UIProps.members) return

		const people =
			allPeople &&
			allPeople.filter(person => {
				return UIProps.members.includes(person.id)
			})

		if (people && people.length > 0) {
			const selectedValues = { Roles: new Set(), Vendors: new Set() }
			people.forEach(person => {
				if (person.role) selectedValues.Roles.add(person.role)
				if (person.companyId) {
					const vendor = allVendors.find(vendor => vendor.id === person.companyId)
					if (vendor) selectedValues.Vendors.add(vendor.company)
				}
			})

			UIProps.setSelectedValues({
				Roles: Array.from(selectedValues.Roles),
				Vendors: Array.from(selectedValues.Vendors)
			})
		}
	}, [UIProps.members])

	const firstIcon = (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
			fill="#2673F0"
		>
			<circle cx="15" cy="15" r="15" />
			<path d="M13.2764 11.3364V9.98443H15.9804V19.4614H14.4854V11.3364H13.2764Z" fill="white" />
		</svg>
	)

	const secondIcon = (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="#2673F0"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" />
			<path
				d="M12.599 17.985C13.431 17.2657 14.0853 16.6763 14.562 16.217C15.0473 15.749 15.4503 15.2637 15.771 14.761C16.0917 14.2583 16.252 13.7557 16.252 13.253C16.252 12.733 16.1263 12.3257 15.875 12.031C15.6323 11.7363 15.2467 11.589 14.718 11.589C14.2067 11.589 13.808 11.7537 13.522 12.083C13.2447 12.4037 13.0973 12.837 13.08 13.383H11.65C11.676 12.395 11.9707 11.641 12.534 11.121C13.106 10.5923 13.8297 10.328 14.705 10.328C15.6497 10.328 16.3863 10.588 16.915 11.108C17.4523 11.628 17.721 12.3213 17.721 13.188C17.721 13.812 17.5607 14.4143 17.24 14.995C16.928 15.567 16.551 16.0827 16.109 16.542C15.6757 16.9927 15.121 17.517 14.445 18.115L13.86 18.635H17.981V19.87H11.663V18.791L12.599 17.985Z"
				fill="white"
			/>
		</svg>
	)

	const thirdIcon = (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="#2673F0"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" />
			<path
				d="M11.754 12.863C11.806 12.057 12.1137 11.4287 12.677 10.978C13.249 10.5273 13.977 10.302 14.861 10.302C15.4677 10.302 15.992 10.4103 16.434 10.627C16.876 10.8437 17.2097 11.1383 17.435 11.511C17.6603 11.8837 17.773 12.304 17.773 12.772C17.773 13.3093 17.63 13.7687 17.344 14.15C17.058 14.5313 16.7157 14.787 16.317 14.917V14.969C16.8283 15.125 17.227 15.411 17.513 15.827C17.799 16.2343 17.942 16.7587 17.942 17.4C17.942 17.9113 17.825 18.3663 17.591 18.765C17.357 19.1637 17.0103 19.48 16.551 19.714C16.0917 19.9393 15.5413 20.052 14.9 20.052C13.964 20.052 13.1927 19.8137 12.586 19.337C11.988 18.8517 11.6673 18.1583 11.624 17.257H13.054C13.0887 17.7163 13.2663 18.0933 13.587 18.388C13.9077 18.674 14.341 18.817 14.887 18.817C15.4157 18.817 15.823 18.674 16.109 18.388C16.395 18.0933 16.538 17.7163 16.538 17.257C16.538 16.6503 16.343 16.2213 15.953 15.97C15.5717 15.71 14.9823 15.58 14.185 15.58H13.847V14.358H14.198C14.9 14.3493 15.433 14.2323 15.797 14.007C16.1697 13.7817 16.356 13.4263 16.356 12.941C16.356 12.525 16.2217 12.1957 15.953 11.953C15.6843 11.7017 15.303 11.576 14.809 11.576C14.3237 11.576 13.9467 11.7017 13.678 11.953C13.4093 12.1957 13.249 12.499 13.197 12.863H11.754Z"
				fill="white"
			/>
		</svg>
	)

	const [showTable, setShowTable] = useState({
		0: true,
		1: false,
		2: false
	})

	const handleChange = (event, newValue) => {
		// Create a new state object with all values set to false
		const newShowTable = {
			0: false,
			1: false,
			2: false
		}

		// Set the newValue key to true
		newShowTable[newValue] = true

		// Update the state
		setShowTable(newShowTable)
	}

	const tabLabels = [
		{ text: "Select Site & Groups", icon: firstIcon, contentAlignment: "center" },
		{ text: "Confirm Recipients", icon: secondIcon, contentAlignment: "center" },
		{ text: "Send Message", icon: thirdIcon, contentAlignment: "center" }
	]

	const tabContents = [
		<div style={{ width: "99%" }}>
			<SelectPeopleTab handleChange={handleChange} allGroups={allGroups} allPeople={allPeople} />
		</div>,
		<div>
			<ConfirmTab onHide={onHide} handleChange={handleChange} />
		</div>,
		<div>
			<SendMessage onHide={onHide} handleChange={handleChange} />
		</div>
	]

	return (
		<div className={classes.root}>
			<TabMenu
				data-testid="manage-site-tab-menu"
				numberOfTabs={tabLabels.length}
				tabLabels={tabLabels}
				tabContents={tabContents}
				showTable={showTable}
				setShowTable={setShowTable}
				disableHeaderClick
			/>
		</div>
	)
}
