import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Route } from "react-router-dom"

import { useUIContext } from "./UIContext"
import InventoryHeader from "./InventoryHeader/InventoryHeader"
import { NodesInventoryCard } from "./NodesInventory/NodesInventoryCard"
import { ModelsInventoryCard } from "./ModelsInventory/ModelsInventoryCard"
import { FirmwaresInventoryCard } from "./FirmwareInventory/FirmwaresInventoryCard"
import * as actionsInventory from "../../_redux/admin/inventory/InventoryActions"
import { ROUTES } from "../_enums/inventory"

const ERROR_FETCHING_INVENTORY_DATA = "Error fetching inventory data:"
const ERROR_FETCHING_TECHNOLOGY_LIST = "Error fetching technology list:"
const WIREPAS = "Wirepas"
const INVENTORY_HEADER = "InventoryHeader"
const NODES_INVENTORY_CARD = "NodesInventoryCard"
const MODELS_INVENTORY_CARD = "ModelsInventoryCard"
const FIRMWARES_INVENTORY_CARD = "FirmwaresInventoryCard"

const InventoryMainCard = ({ history }) => {
	const dispatch = useDispatch()
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			setTechnology: UIContext.setTechnology
		}
	}, [UIContext])

	useEffect(() => {
		history && history.push(`${ROUTES.NODES_ROUTE}`)
	}, [history])

	//? I had to set technology here so that the state updates for Export Dropdown and Inventory Table
	//? With useUIContext was not working
	const [technology, setTechnology] = useState(null)
	const [technologyList, setTechnologyList] = useState(null)

	const {
		isSuper,
		nodesInventory,
		modelsInventory,
		firmwaresInventory,
		customers,
		currentCustomer,
		permissions
	} = useSelector(
		state => ({
			isSuper: state.auth.claims.isSuper,
			currentCustomer: state.profile?.currentCustomer,
			nodesInventory: state.inventory.nodesInventory,
			modelsInventory: state.inventory.modelsInventory,
			firmwaresInventory: state.inventory.firmwaresInventory,
			customers: state.inventory.customers,
			permissions: state.auth?.permissions?.permissions
		}),
		shallowEqual
	)

	// Fetch technology list if user is super
	useEffect(() => {
		let isMounted = true

		if (!isSuper) return

		const fetchTechnologyList = async () => {
			try {
				const result = await dispatch(actionsInventory.getTechnologyList())
				if (isMounted && result && result.includes(WIREPAS)) {
					setTechnologyList(result)
					setTechnology(WIREPAS)
				}
			} catch (error) {
				if (isMounted) {
					console.error(ERROR_FETCHING_TECHNOLOGY_LIST, error)
				}
			}
		}

		fetchTechnologyList()

		return () => {
			isMounted = false
		}
	}, [isSuper, dispatch])

	// Fetch inventory data based on technology
	useEffect(() => {
		let isMounted = true

		if (!technology || !isSuper || !currentCustomer.id) return

		const fetchData = async () => {
			try {
				await dispatch(
					actionsInventory.getNodes({
						technology: technology,
						customerId: currentCustomer.id
					})
				)
				await dispatch(
					actionsInventory.getModels({
						technology: technology,
						customerId: currentCustomer.id
					})
				)
				await dispatch(
					actionsInventory.getFirmwares({
						technology: technology,
						customerId: currentCustomer.id
					})
				)
				await dispatch(actionsInventory.getCustomersAndSites())
			} catch (error) {
				if (isMounted) {
					console.error(ERROR_FETCHING_INVENTORY_DATA, error)
				}
			}
		}

		fetchData()

		return () => {
			isMounted = false
		}
	}, [technology, dispatch])

	const handleTechnology = (event, newValue) => {
		setTechnology(newValue)
	}

	useEffect(() => {
		if (!technology) return
		UIProps.setTechnology(technology)
	}, [technology])

	return (
		<>
			<InventoryHeader data-testid={INVENTORY_HEADER} />
			<Route path={ROUTES.NODES_ROUTE}>
				{({ history, match }) =>
					match &&
					permissions?.Operations?.Inventory?.Nodes && (
						<NodesInventoryCard
							nodesInventory={nodesInventory}
							technology={technology}
							setTechnology={setTechnology}
							handleTechnology={handleTechnology}
							technologyList={technologyList}
							allCustomers={customers}
							firmwaresInventory={firmwaresInventory}
							data-testid={NODES_INVENTORY_CARD}
						/>
					)
				}
			</Route>
			<Route path={ROUTES.MODELS_ROUTE}>
				{({ history, match }) =>
					match &&
					permissions?.Operations?.Inventory?.Models && (
						<ModelsInventoryCard
							modelsInventory={modelsInventory}
							technology={technology}
							setTechnology={setTechnology}
							handleTechnology={handleTechnology}
							technologyList={technologyList}
							data-testid={MODELS_INVENTORY_CARD}
						/>
					)
				}
			</Route>
			<Route path={ROUTES.FIRMWARES_ROUTE}>
				{({ history, match }) =>
					match &&
					permissions?.Operations?.Inventory?.Firmwares && (
						<FirmwaresInventoryCard
							firmwaresInventory={firmwaresInventory}
							technology={technology}
							setTechnology={setTechnology}
							handleTechnology={handleTechnology}
							technologyList={technologyList}
							data-testid={FIRMWARES_INVENTORY_CARD}
						/>
					)
				}
			</Route>
		</>
	)
}

export default InventoryMainCard
