export const ERRORS = {
	TYPES: {
		SNACKBAR_ERROR: "SNACKBAR_ERROR"
	},
	SNACKBAR_ERRORS: {
		INVALID_PHONE_NUMBER: "Error: Phone number should have at least 5 digits.",
		INVALID_EMAIL: "Error: Invalid email address.",
		INVALID_PASSWORD: "Error: Password should have at least 6 characters.",
		INVALID_PASSWORDS: "Error: Passwords do not match.",
		INVALID_NAME: "Error: Name should have at least 2 characters.",
		INVALID_USERNAME: "Error: Username should have at least 3 characters.",
		INVALID_BIRTHDATE: "Error: Birthdate should be in the past."
	},
	FETCH_ERRORS: {
		IMAGE_URL: "Error fetching image URL:",
		SENSORS_DATA: "Error fetching sensors data."
	},
	UPDATING_ERRORS: {
		PEOPLE_DATA: "Error updating people data:"
	},
	SERVER_ERRORS: {
		INTERNAL_SERVER: "Can't make a request for server."
	}
}
