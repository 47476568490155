export const DOUBLE_ROUTE_BUTTON = {
	STYLES: {
		BUTTONS: {
			FONT_SIZE: "16px",
			NO_BORDER_RADIUS: 0
		},
		ICONS: {
			HEIGHT: "2rem",
			WIDTH: "2rem",
			ACTIVE_FILL: "#FFFFFF",
			INACTIVE_FILL: "#8C8CA1",
			MARGIN_RIGHT: "10px"
		}
	}
}
