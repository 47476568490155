import React, { useState } from "react"
import { useSelector, shallowEqual } from "react-redux"

import { Card, CardBody } from "../../../../_partials/Card"
import ListRowWithAvatar from "../../../../_partials/ListRowWithAvatar"
import withReactWindowList from "../../../../_partials/HOCs/withReactWindowList"
import withAutoSizer from "../../../../_partials/HOCs/withAutoSizer"

import { useQueryDb } from "../../../../_hooks/useQueryDb"
import { COLLECTIONS, MESSAGING, QUERY_CONSTRAINTS } from "../../../../_enums/firestoreConstants"
import { ANNOUNCEMENTS } from "../../../../_enums/notifications"

export default function Announcements() {
	const { selectedCustomer, messagingSwitch, user } = useSelector(
		state => ({
			user: state.auth?.user,
			selectedCustomer: state.profile?.currentCustomer,
			messagingSwitch: state.notifications?.messagingSwitch
		}),
		shallowEqual
	)

	// const [announcements, setAnnouncements] = useState([])
	const [activeItem, setActiveItem] = useState(null)

	const queryConfig = {
		user,
		messagingSwitch,
		collectionPath:
			selectedCustomer?.id &&
			`${COLLECTIONS.CUSTOMERS}/${selectedCustomer.id}/${COLLECTIONS.MESSAGES}`,
		queryParams: {
			where: [
				[
					QUERY_CONSTRAINTS.WHERE.TYPE,
					QUERY_CONSTRAINTS.OPERATORS.EQUAL,
					MESSAGING.TYPES.ANNOUNCEMENT
				]
			],
			orderBy: [[QUERY_CONSTRAINTS.ORDER_BY.DATE, QUERY_CONSTRAINTS.ORDER_BY.DESC]]
		}
	}

	const announcements = useQueryDb(queryConfig)

	const handleItemClick = item => {
		setActiveItem(item)
	}

	const ReactWindowList = withReactWindowList(ListRowWithAvatar)
	const AnnouncementsList = withAutoSizer(ReactWindowList)

	return (
		<Card
			className="announcement-card"
			isLoading={false}
			style={{ minHeight: "170px", padding: "0px 0px" }}
		>
			<div
				className="d-flex justify-content-start"
				style={{
					fontFamily: "Poppins",
					fontSize: "13px",
					fontWeight: "500",
					lineHeight: "23px",
					color: "#1C1C3B",
					padding: "10px 10px 5px 10px",
					minHeight: "30px"
				}}
			>
				{ANNOUNCEMENTS.ANNOUNCEMENTS}
			</div>
			<CardBody className="announcement-card-body" padding="0px 0px 0px 10px" style={{}}>
				{announcements.length === 0 && (
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: "100px" }}
					>
						{ANNOUNCEMENTS.NO_ANNOUNCEMENTS}
					</div>
				)}
				<AnnouncementsList
					listItems={announcements}
					className="announcement-list"
					isLoading={false}
					activeItem={activeItem}
					onItemClick={handleItemClick}
				/>
			</CardBody>
		</Card>
	)
}
