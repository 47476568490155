import React, { useState, useEffect } from "react"
import moment from "moment"

import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"

import { useFetchDownloadUrl } from "../_hooks/useFetchDownloadUrl"

const listItemSecondaryLineSpanStyle = {
	fontFamily: "Poppins",
	fontSize: "8px",
	fontWeight: 500,
	lineHeight: "20px",
	color: "#5C5F62"
}

const FROM = "From: "

export default function ListRowWithAvatar({ listItem, isActive }) {
	const [userAvatarDownloadUrl, setUserAvatarDownloadUrl] = useState("")

	useEffect(() => {
		useFetchDownloadUrl(listItem.user?.profilePath).then(url => setUserAvatarDownloadUrl(url))
	}, [listItem])

	return (
		<ListItem
			alignItems="flex-start"
			sx={{
				maxHeight: "35px",
				padding: "0px 10px 0px 0px",
				overflow: "hidden"
			}}
		>
			<ListItemAvatar sx={{ minWidth: 0, marginRight: "10px" }}>
				<Avatar
					data-testid="avatar"
					src={userAvatarDownloadUrl}
					sx={{ width: 24, height: 24 }}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "9.5px",
							fontWeight: 400,
							lineHeight: "12px",
							display: "-webkit-box",
							WebkitLineClamp: 2, // Limit to 2 lines
							WebkitBoxOrient: "vertical",
							overflow: "hidden",
							textOverflow: "ellipsis" // Optional: adds "..." if content is truncated
						}}
						component="div"
					>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							{listItem.body}
							{isActive && (
								<span
									style={{
										backgroundColor: "#2673F0",
										width: "10px",
										height: "10px",
										display: "inline-block",
										borderRadius: "50%"
									}}
								/>
							)}
						</div>
					</Typography>
				}
				secondary={
					<Typography
						sx={{
							fontFamily: "Poppins",
							fontSize: "8px",
							fontWeight: 500,
							lineHeight: "20px",
							color: "#2673F0",
							display: "-webkit-box",
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
							overflow: "hidden",
							textOverflow: "ellipsis",
							textWrap: "nowrap"
						}}
						component="span"
					>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<span>
								<span style={listItemSecondaryLineSpanStyle}>{FROM}</span>
								{`${listItem.from} `}
							</span>
							<span style={listItemSecondaryLineSpanStyle}>
								{moment.unix(listItem.date.seconds).format("DD MMM YYYY - hh:mmA")}
							</span>
						</div>
					</Typography>
				}
			/>
		</ListItem>
	)
}
