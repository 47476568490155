/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { Modal, Backdrop, Badge } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MailOutlinedIcon from "@material-ui/icons/MailOutlined"

import { Button } from "../../../../_partials/Button"
import FirebaseMessagingModalBody from "./FirebaseMessagingModalBody"
import { useQueryDb } from "../../../../_hooks/useQueryDb"
import { playSound } from "./_utils/playSound"
import { COLLECTIONS, MESSAGING, QUERY_CONSTRAINTS } from "../../../../_enums/firestoreConstants"
import { COLORS } from "../../../../_enums/colors"

const useStyles = makeStyles(() => ({
	badge: {
		marginRight: "5px",
		"& .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible": {
			transform: "scale(0) translate(25%, 0%)"
		},
		"& .MuiBadge-anchorOriginTopRightRectangle": {
			transform: "scale(1) translate(25%, 0%)"
		}
	},
	shakeAnimation: {
		animation: "$shake 500ms",
		animationIterationCount: 3,
		animationTimingFunction: "cubic-bezier(0.36, 0.07, 0.19, 0.97)"
	},
	"@keyframes shake": {
		"0%": {
			transform: "translateX(0)"
		},
		"20%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"40%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"60%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"80%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"100%": {
			transform: "translateX(0) rotate(0)"
		}
	}
}))

const FirebaseMessaging = () => {
	const { selectedCustomer, messagingSwitch, user, messagingVolume } = useSelector(
		state => ({
			user: state.auth?.user,
			selectedCustomer: state.profile?.currentCustomer,
			messagingSwitch: state.notifications?.messagingSwitch,
			messagingVolume: state.notifications?.messagingVolume || 50
		}),
		shallowEqual
	)

	const [buttonColor, setButtonColor] = useState()
	const [newMessagesCounter, setNewMessagesCounter] = useState(0)
	const [open, setOpen] = useState(false)
	const [showAnimation, setShowAnimation] = useState(false)

	const queryConfig = {
		user,
		messagingSwitch,
		collectionPath:
			selectedCustomer?.id &&
			`${COLLECTIONS.CUSTOMERS}/${selectedCustomer.id}/${COLLECTIONS.MESSAGES}`,
		queryParams: {
			where: [
				[
					// "type", "==", "message"
					QUERY_CONSTRAINTS.WHERE.TYPE,
					QUERY_CONSTRAINTS.OPERATORS.EQUAL,
					MESSAGING.TYPES.MESSAGE
				],
				[
					// "erase", "==", false
					QUERY_CONSTRAINTS.WHERE.ERASE,
					QUERY_CONSTRAINTS.OPERATORS.EQUAL,
					QUERY_CONSTRAINTS.VALUES.FALSE
				],
				[
					// "uidTo", "==", `${user?.id}-messages`
					QUERY_CONSTRAINTS.WHERE.UID_TO,
					QUERY_CONSTRAINTS.OPERATORS.EQUAL,
					`${user?.id}-${QUERY_CONSTRAINTS.VALUES.MESSAGES}`
				],
				[
					// "date", ">=", new Date(Date.now() - 24 * 60 * 60 * 1000)
					QUERY_CONSTRAINTS.WHERE.DATE,
					QUERY_CONSTRAINTS.OPERATORS.GREATER_THAN_OR_EQUAL,
					// last 24 hours
					new Date(Date.now() - 24 * 60 * 60 * 1000)
				]
			],
			orderBy: [[QUERY_CONSTRAINTS.ORDER_BY.DATE, QUERY_CONSTRAINTS.ORDER_BY.DESC]]
		}
	}

	const allMessages = useQueryDb(queryConfig)

	useEffect(() => {
		if (!allMessages) return

		let counter = 0
		allMessages.forEach(message => {
			if (!message.read) {
				counter++
				setShowAnimation(true)
			}
		})

		if (counter > newMessagesCounter) {
			playSound(messagingVolume)
		}

		setNewMessagesCounter(counter)

		if (counter > 0) {
			setButtonColor(COLORS.PRIMARY_LIGHT)
		}
	}, [allMessages])

	const handleOpenMessaging = () => {
		setButtonColor(COLORS.PRIMARY_LIGHT)
		setOpen(true)
	}

	const handleCloseMessaging = () => {
		if (newMessagesCounter <= 0) setButtonColor()
		setOpen(false)
	}

	const classes = useStyles()

	return (
		<>
			<Badge
				key={newMessagesCounter}
				color="primary"
				max={99}
				badgeContent={newMessagesCounter}
				className={`${classes.badge} ${
					showAnimation ? classes.shakeAnimation : ""
				} d-flex align-items-center`}
			>
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="firebase-messaging-tooltip">Messaging</Tooltip>}
				>
					<Button
						color={buttonColor}
						startIcon={<MailOutlinedIcon fontSize="large" className="badge-start-icon" />}
						style={{
							width: "36px",
							height: "36px",
							borderRadius: "25px",
							minWidth: "0"
						}}
						onClick={handleOpenMessaging}
					/>
				</OverlayTrigger>
			</Badge>
			<Modal
				open={open}
				onClose={handleCloseMessaging}
				BackdropComponent={props => <Backdrop {...props} />}
			>
				<div>
					<FirebaseMessagingModalBody allMessages={allMessages} />
				</div>
			</Modal>
		</>
	)
}

export default FirebaseMessaging
