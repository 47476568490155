import notificationSound from "../../../../../assets/sounds/notificationSound.wav"

export const playSound = messagingVolume => {
	const AudioContext = window.AudioContext || window.webkitAudioContext
	const audioContext = new AudioContext()

	fetch(notificationSound)
		.then(response => response.arrayBuffer())
		.then(buffer => audioContext.decodeAudioData(buffer))
		.then(decodedData => {
			const source = audioContext.createBufferSource()
			source.buffer = decodedData

			const gainNode = audioContext.createGain()
			gainNode.gain.value = messagingVolume >= 5 ? messagingVolume / 75 : 0
			source.connect(gainNode)
			gainNode.connect(audioContext.destination)

			source.start(0)
		})
		.catch(error => {
			console.log("Error loading audio:", error)
		})
}
