import React from "react"
import { Route } from "react-router-dom"

import { UIProvider } from "./UIContext"
import InventoryMainCard from "./InventoryMainCard"
import EditInventoryModal from "./EditInventory/EditInventoryModal"
import UnassignInventoryModal from "./UnassignInventory/UnassignInventoryModal"
import AssignInventoryModal from "./AssignInventory/AssignInventoryModal"
import PrintNodeLabel from "./PrintLabel/PrintNodeLabel"
import EditInventoryModelsModal from "./ModelsInventory/EditInventoryModelsModal"
import DeleteInventoryModelsModal from "./ModelsInventory/DeleteInventoryModelsModal"
import DeleteInventoryFirmwaresModal from "./FirmwareInventory/DeleteInventoryFirmwaresModal"
import DeleteNodes from "./DeleteNodes/DeleteNodes"
import { ROUTES, MODALS } from "../_enums/inventory"

export const InventoryPage = ({ history }) => {
	const handleLocationChange = () => {
		if (!history?.location?.pathname) return

		const path = history?.location?.pathname
		const headerControls = document.querySelector("#kt_header > div > div:nth-child(2)")
		if (path.includes("inventory")) {
			if (headerControls) {
				headerControls.style.pointerEvents = "none"
				headerControls.style.opacity = "0.5"
			}
		} else {
			// Reset styles if the path does not include "inventory"
			if (headerControls) {
				headerControls.style.pointerEvents = ""
				headerControls.style.opacity = ""
			}
		}
	}

	React.useEffect(() => {
		handleLocationChange()

		const unlisten = history && history.listen(handleLocationChange)

		// Clean up on unmount
		return () => {
			unlisten && unlisten()
		}
	}, [history])

	const UIEvents = {
		openEditInventoryNodesModal: () => {
			history.push(ROUTES.EDITE_INVENTORY_NODES)
		},
		openUnassignInventoryNodesModal: () => {
			history.push(ROUTES.UNASSIGN_INVENTORY_NODES)
		},
		openDeleteInventoryNodesModal: () => {
			history.push(ROUTES.DELETE_INVENTORY_NODES)
		},
		openAssignInventoryNodesModal: () => {
			history.push(ROUTES.ASSIGN_INVENTORY_NODES)
		},
		openPrintNodeLabelModal: () => {
			history.push(ROUTES.PRINT_NODE_LABEL)
		},
		openEditInventoryModelsModal: () => {
			history.push(ROUTES.EDIT_INVENTORY_MODELS)
		},
		openDeleteInventoryModelsModal: () => {
			history.push(ROUTES.DELETE_INVENTORY_MODELS)
		},
		openDeleteInventoryFirmwaresModal: () => {
			history.push(ROUTES.DELETE_INVENTORY_FIRMWARES)
		}
	}

	return (
		<UIProvider UIEvents={UIEvents}>
			<>
				<Route path={ROUTES.EDITE_INVENTORY_NODES} data-testid={MODALS.EDIT_INVENTORY_MODAL}>
					{({ history, match }) => (
						<EditInventoryModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route
					path={ROUTES.UNASSIGN_INVENTORY_NODES}
					data-testid={MODALS.UNASSIGN_INVENTORY_MODAL}
				>
					{({ history, match }) => (
						<UnassignInventoryModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route
					path={ROUTES.DELETE_INVENTORY_NODES}
					data-testid={MODALS.DELETE_INVENTORY_NODES_MODAL}
				>
					{({ history, match }) => (
						<DeleteNodes
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route path={ROUTES.ASSIGN_INVENTORY_NODES} data-testid={MODALS.ASSIGN_INVENTORY_MODAL}>
					{({ history, match }) => (
						<AssignInventoryModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route path={ROUTES.PRINT_NODE_LABEL} data-testid={MODALS.PRINT_NODE_LABEL_MODAL}>
					{({ history, match }) => (
						<PrintNodeLabel
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route
					path={ROUTES.EDIT_INVENTORY_MODELS}
					data-testid={MODALS.EDIT_INVENTORY_MODELS_MODAL}
				>
					{({ history, match }) => (
						<EditInventoryModelsModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route
					path={ROUTES.DELETE_INVENTORY_MODELS}
					data-testid={MODALS.DELETE_INVENTORY_MODELS_MODAL}
				>
					{({ history, match }) => (
						<DeleteInventoryModelsModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<Route
					path={ROUTES.DELETE_INVENTORY_FIRMWARES}
					data-testid={MODALS.DELETE_INVENTORY_FIRMWARES_MODAL}
				>
					{({ history, match }) => (
						<DeleteInventoryFirmwaresModal
							show={match != null}
							onHide={() => {
								history.push(ROUTES.MAIN_ROUTE)
							}}
						/>
					)}
				</Route>

				<InventoryMainCard history={history} />
			</>
		</UIProvider>
	)
}
