import React from "react"
import { Dropdown } from "react-bootstrap"
import { Pagination } from "@material-ui/lab"
import { useGridApiContext } from "@mui/x-data-grid"

const CustomPagination = ({ rows, classes, options }) => {
	const apiRef = useGridApiContext()
	const state = apiRef.current.state

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>

			<span style={{ minWidth: "110px" }}>Total Rows: {rows?.length || 0}</span>
		</>
	)
}

export default CustomPagination
