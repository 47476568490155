import React from "react"
import { Button } from "./Button"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../_metronic/_helpers"
import { BUTTONS } from "../_enums/buttons"
import { DOUBLE_ROUTE_BUTTON } from "../_enums/doubleRouteButton"

const DoubleRouteButton = ({ buttons, activeId, onButtonClick }) => {
	const buttonStyles = firstButton => ({
		fontSize: DOUBLE_ROUTE_BUTTON.STYLES.BUTTONS.FONT_SIZE,
		borderTopRightRadius: firstButton && DOUBLE_ROUTE_BUTTON.STYLES.BUTTONS.NO_BORDER_RADIUS,
		borderBottomRightRadius: firstButton && DOUBLE_ROUTE_BUTTON.STYLES.BUTTONS.NO_BORDER_RADIUS,
		borderTopLeftRadius: !firstButton && DOUBLE_ROUTE_BUTTON.STYLES.BUTTONS.NO_BORDER_RADIUS,
		borderBottomLeftRadius: !firstButton && DOUBLE_ROUTE_BUTTON.STYLES.BUTTONS.NO_BORDER_RADIUS
	})

	const iconStyles = isActive => ({
		height: DOUBLE_ROUTE_BUTTON.STYLES.ICONS.HEIGHT,
		width: DOUBLE_ROUTE_BUTTON.STYLES.ICONS.WIDTH,
		fill: isActive
			? DOUBLE_ROUTE_BUTTON.STYLES.ICONS.ACTIVE_FILL
			: DOUBLE_ROUTE_BUTTON.STYLES.ICONS.INACTIVE_FILL,
		marginRight: DOUBLE_ROUTE_BUTTON.STYLES.ICONS.HEIGHT
	})

	return (
		<>
			{buttons.map((btn, index) => (
				<Button
					key={btn.id}
					color={activeId === btn.id ? BUTTONS.COLORS.PRIMARY : BUTTONS.COLORS.GREY_LIGHT}
					variant={
						activeId === btn.id ? BUTTONS.VARIANTS.CONTAINED : BUTTONS.VARIANTS.OUTLINED
					}
					onClick={() => onButtonClick(btn.id)}
					style={buttonStyles(index === 0)}
					startIcon={
						<SVG src={toAbsoluteUrl(btn.icon)} style={iconStyles(activeId === btn.id)} />
					}
					data-testid={`${btn.id}Button`}
					text={btn.label}
				/>
			))}
		</>
	)
}

export default DoubleRouteButton
