import React from "react"
import "./Header.css"
import HeaderDropdownOnMobile from "./HeaderDropdownOnMobile"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import DoubleRouteButton from "../../../../_partials/DoubleRouteButton"
import { OPERATIONS_HEADER } from "../../_enums/header"

export function Header({ page, setPage }) {
	const buttons = OPERATIONS_HEADER.BUTTONS.ARRAY_OF_BUTTONS

	return (
		<div className="mb-10">
			<div className="row justify-content-center">
				<div className="col d-flex align-items-center">
					<div className="header-title-svg-div">
						<SVG
							src={toAbsoluteUrl(OPERATIONS_HEADER.SVG.PATH)}
							className="header-title-svg"
						/>
					</div>

					<h2 style={{ color: OPERATIONS_HEADER.STYLES.TITLE.COLOR }}>
						<b>{OPERATIONS_HEADER.TEXTS.TITLE}</b>
					</h2>
				</div>
				<div className="d-none d-sm-flex">
					<DoubleRouteButton
						buttons={buttons}
						activeId={page}
						onButtonClick={id => setPage(id)}
					/>
				</div>
				<div className="d-flex d-sm-none justify-content-center m-5">
					<div className="header_dropdown_mobile_view">
						<HeaderDropdownOnMobile page={page} setPage={setPage} />
					</div>
				</div>
			</div>
		</div>
	)
}
