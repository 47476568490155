import React from "react"
import { Drawer as MuiDrawer } from "@material-ui/core"

const Drawer = ({ anchor, variant, open, onClose, classes, children, ...props }) => {
	return (
		<MuiDrawer
			anchor={anchor}
			variant={variant}
			open={open}
			onClose={onClose}
			classes={classes}
			{...props}
		>
			{children}
		</MuiDrawer>
	)
}

export default Drawer
