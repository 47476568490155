import React, { useState, useEffect, useMemo } from "react"

import { useSelector, shallowEqual } from "react-redux"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SVG from "react-inlinesvg"

import CheckIcon from "@material-ui/icons/Check"
import { DataGrid } from "@mui/x-data-grid"

import { useUIContext } from "../UIContext"
import { storage } from "../../../../../firebase"

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { ProgressBar } from "../../../../../_metronic/layout/components/extras/ProgressBar"
import inventoryFiltersIcon from "../../../../../app/assets/ck-icons/inventory-filters-icon.svg"
import DeleteInventoryFirmwaresModal from "./DeleteInventoryFirmwaresModal"
import { filterBySearchString } from "../../../Admin/manage-customer/UIHelpers"

import { Button } from "../../../../_partials/Button"
import CustomPagination from "../_partials/CustomPagination"
import { CustomNoRowsOverlay, CustomNoTechnologyOverlay } from "../_partials/CustomGridOverlays"
import { QuickToolbar } from "../_partials/QuickToolbar"
import { useStyles } from "../_partials/DataGridStylesOverride"

import "../Inventory.css"
import { DATA_GRID } from "../_enums/dataGrid"

export default function FirmwaresInventoryTable({
	filterVersionOptions,
	filterSeverityOptions,
	filterNameOptions,
	filterVendorOptions,
	technology,
	rows,
	setRows,
	searchText
}) {
	// UI Context
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryFirmwares,
			setIds: UIContext.setIdsInventoryFirmwares,
			setTechnology: UIContext.setTechnology,
			setCurrentFirmwaresTablePageInfo: UIContext.setCurrentFirmwaresTablePageInfo,
			setRowsToUseFirmwaresTable: UIContext.setRowsToUseFirmwaresTable,
			setColumnVisibilityModelFirmwaresTable: UIContext.setColumnVisibilityModelFirmwaresTable,
			columnVisibilityModelFirmwaresTable: UIContext.columnVisibilityModelFirmwaresTable
		}
	}, [UIContext])

	// Redux state
	const { firmwaresInventory, listLoading, floorPlans, modelsInventory } = useSelector(
		state => ({
			firmwaresInventory: state.inventory.firmwaresInventory,
			modelsInventory: state.inventory.modelsInventory,
			listLoading: state.inventory.listLoading,
			floorPlans: state.basePage.floorPlans
		}),
		shallowEqual
	)

	// Local state
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [activeFilters, setActiveFilters] = useState(false)
	const [searchedRows, setSearchedRows] = useState(null)
	const [selectionModel, setSelectionModel] = React.useState([])
	const [showDeleteFirmware, setShowDeleteFirmware] = useState(false)
	const [rowsToUse, setRowsToUse] = useState([])
	const [currentRows, setCurrentRows] = useState([])
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [sortModel, setSortModel] = useState([
		{
			field: "name",
			sort: "asc"
		}
	])
	const classes = useStyles()

	useEffect(() => {
		if (!firmwaresInventory || firmwaresInventory.length <= 0 || !technology) return

		// Create a shallow copy of the firmwaresInventory array
		let unfilteredFirmwares = firmwaresInventory.map(firmware => ({ ...firmware }))
		const promises = unfilteredFirmwares.map(firmware => getDownloadUrl(firmware.firmwarePath))

		// Fetch download URLs for firmware paths
		Promise.all(promises)
			.then(urls => {
				unfilteredFirmwares.forEach((firmware, index) => {
					// Update the firmware with the download URL
					firmware.firmwarePathUrl = urls[index]
				})
				// Update the rows state with firmwarePathUrl included
				setRows(unfilteredFirmwares)
			})
			.catch(error => {
				console.error("Error fetching firmware URLs:", error)
			})

		if (
			!filterVersionOptions ||
			!filterSeverityOptions ||
			!filterNameOptions ||
			!filterVendorOptions
		)
			return

		const anyFilterActive =
			filterVersionOptions.find(filter => filter.selected) ||
			filterSeverityOptions.find(filter => filter.selected) ||
			filterNameOptions.find(filter => filter.selected) ||
			filterVendorOptions.find(filter => filter.selected)

		let filteredRows = null
		if (anyFilterActive) {
			setActiveFilters(true)

			filteredRows = unfilteredFirmwares.filter(
				node =>
					filterVersionOptions.find(
						filter => filter.node === node.version && filter.selected
					) ||
					filterSeverityOptions.find(
						filter => filter.node === node.severity && filter.selected
					) ||
					filterNameOptions.find(filter => filter.node === node.name && filter.selected) ||
					filterVendorOptions.find(filter => filter.node === node.vendor && filter.selected)
			)
			setRows(filteredRows)
		} else {
			setActiveFilters(false)
			setRows(unfilteredFirmwares)
		}
	}, [
		firmwaresInventory,
		filterVersionOptions,
		filterNameOptions,
		filterSeverityOptions,
		filterVendorOptions,
		technology,
		floorPlans
	])

	// search logic with filterBySearchString
	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)

		// Define the fields to search
		const fieldsToSearch = [
			DATA_GRID.FIELDS.VERSION,
			DATA_GRID.FIELDS.SEVERITY,
			DATA_GRID.FIELDS.VENDOR,
			DATA_GRID.FIELDS.NAME,
			DATA_GRID.FIELDS.NOTES,
			DATA_GRID.FIELDS.MODEL_ID
		]
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	// rowsToUse is responsible for saving the data state when ever a new data filters appears
	// this means on searching, sorting, filtering or hiding rowsToUse will always have all data
	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	// currentRows is responsible for keeping track of the current rows the user is viewing
	// directly connect to page and pageSize for selection rows to be working properly
	useEffect(() => {
		updateCurrentRows()
		UIProps.setCurrentFirmwaresTablePageInfo({ page: page, pageSize: pageSize })
	}, [rowsToUse, page, pageSize])

	// this controlers the rowsCount prop from data grid
	// it enables to change page and page size and keep track of past selected rows and "select all" only for current page
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])

	// Update the handleSortModelChange function in your PeopleTable component
	const handleSortModelChange = newSortModel => {
		// Sort the full dataset (rowsToUse) based on the sort model
		const sortedRows = [...rowsToUse].sort((a, b) => {
			// Iterate over the sort model
			for (const sortItem of newSortModel) {
				const field = sortItem.field
				// Only consider asc and desc
				const sortOrder = sortItem.sort === "asc" ? 1 : -1
				if (sortItem.sort !== null) {
					if (field === "disabled") {
						// Sort based on boolean value for the "disabled" field
						if (a[field] && !b[field]) return sortOrder
						if (!a[field] && b[field]) return -sortOrder
						continue
					}
					// For other fields, handle sorting based on their type
					if (a[field] === null && b[field] === null) continue // If both values are null, maintain current order
					if (a[field] === null) return -sortOrder // If 'a' is null, place it before 'b'
					if (b[field] === null) return sortOrder // If 'b' is null, place it before 'a'
					// Sort non-null values based on column type
					if (typeof a[field] === "string") {
						const comparison = a[field].localeCompare(b[field], undefined, { numeric: true })
						if (comparison !== 0) return comparison * sortOrder
					} else if (typeof a[field] === "number") {
						return (a[field] - b[field]) * sortOrder
					}
				}
			}
			// If no column has a defined sort order, maintain current order
			return 0
		})

		// Update the sorted data and currentRows
		setRowsToUse(sortedRows) //all data
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}
	// Function to update the current rows based on the current page and page size
	const updateCurrentRows = () => {
		const startIndex = page * pageSize
		const endIndex = Math.min(startIndex + pageSize, rowsToUse.length)
		const newCurrentRows = rowsToUse.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(rowsToUse.length)
		// prints all data
		UIProps.setRowsToUseFirmwaresTable(rowsToUse)
	}

	const getDownloadUrl = firmwarePath => {
		if (!firmwarePath || firmwarePath === "") {
			return null
		}

		return storage
			.ref(firmwarePath)
			.getDownloadURL()
			.catch(err => console.log("Error: ", err))
	}

	const columns = [
		{
			field: "actions",
			headerName: "Actions",
			hideable: false,
			renderCell: params => {
				const row = params.row
				return (
					<div data-testid="actions-cell">
						<>
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="firebase-Edit-tooltip">Delete Selected Models</Tooltip>
								}
							>
								<Button
									variant="contained"
									color="errorLight"
									className="MUICustom_secondary"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									startIcon={
										<SVG
											className="MUICustom_secondary_svg"
											src={toAbsoluteUrl("/media/svg/icons/General/trash2.svg")}
										/>
									}
									onClick={() => {
										UIProps.setIds([params.row.id])
										setSelectionModel([params.row.id])
										setShowDeleteFirmware(true)
									}}
									data-testid="unassign-button"
								/>
							</OverlayTrigger>
						</>
					</div>
				)
			},
			editable: false
		},
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			flex: 1,
			type: "string",
			minWidth: 150,
			hideable: false
		},
		{
			field: "version",
			headerName: "Version",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { version, firmwarePathUrl } = params.row

				if (firmwarePathUrl) {
					return (
						<a href={firmwarePathUrl} download>
							{version}
						</a>
					)
				}
				return <span>{version}</span>
			}
		},
		{
			field: "severity",
			headerName: "Severity",
			type: "string",
			minWidth: 150
		},
		{
			field: "vendor",
			headerName: "Vendor",
			type: "string",
			minWidth: 200
		},
		{
			field: "modelId",
			headerName: "Model",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { modelId } = params.row

				const model = modelsInventory.filter(model => model.id === modelId)

				if (model && model.length > 0) {
					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">{modelId}</Tooltip>}
						>
							<span>{model[0].name}</span>
						</OverlayTrigger>
					)
				}
				return <span>{modelId}</span>
			}
		},
		{
			field: "notes",
			headerName: "Notes",
			type: "string",
			minWidth: 200
		}
	]

	// number of rows displayed in the table
	const rowsPerPageOptions = [5, 10, 20, 30, 50, 100]

	return (
		<div style={{ width: "100%" }} data-testid="FirmwaresInventoryTable">
			<div className="row justify-content-end text-end">
				<span className="selected-filters-title">Selected filters</span>
			</div>
			<div className="row mb-5" style={{ marginRight: "2px" }}>
				{/* //TODO list selected filters options */}
				<div
					className="col d-flex flex-wrap-reverse justify-content-end pr-0"
					style={{
						maxHeight: "75px",
						overflow: "auto"
					}}
				>
					{!activeFilters && (
						<div>
							<div className="no-selected-filters">
								<span>
									NO SELECTED FILTERS <img src={inventoryFiltersIcon} />
								</span>
							</div>
						</div>
					)}
					{filterVersionOptions &&
						filterVersionOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterNameOptions &&
						filterNameOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterSeverityOptions &&
						filterSeverityOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterVendorOptions &&
						filterVendorOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
				</div>
			</div>
			{listLoading && <ProgressBar.LinearIndeterminate />}
			<DataGrid
				style={{
					fontFamily: "Poppins",
					fontWeight: 400,
					fontSize: "13px",
					border: "none",
					width: "100%",
					color: "#464e5f"
				}}
				rows={currentRows}
				columns={columns}
				className={classes.root}
				density="standard"
				pagination
				disableSelectionOnClick
				loading={listLoading || !technology}
				disableColumnMenu={true}
				components={{
					Toolbar: QuickToolbar,
					Pagination: props => (
						<CustomPagination
							{...props}
							rows={rowsToUse}
							classes={classes}
							options={rowsPerPageOptions}
						/>
					),
					NoRowsOverlay: technology ? CustomNoRowsOverlay : CustomNoTechnologyOverlay
				}}
				rowsPerPageOptions={rowsPerPageOptions}
				page={page}
				onPageChange={newPage => {
					setPage(newPage)
				}}
				pageSize={pageSize}
				onPageSizeChange={e => {
					setPageSize(e)
				}}
				autoHeight
				rowCount={rowCountState}
				// to selected only current page rows
				paginationMode="server"
				onSelectionModelChange={newSelectionModel => {
					UIProps.setIds(newSelectionModel)
					setSelectionModel(newSelectionModel)
				}}
				selectionModel={selectionModel}
				// to be able to change page and keep selected rows
				keepNonExistentRowsSelected
				// for printing with matching table order
				sortingOrder={["desc", "asc"]}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
				// for printing only visible columns
				onColumnVisibilityModelChange={e => {
					UIProps.setColumnVisibilityModelFirmwaresTable(e)
				}}
				columnVisibilityModel={UIProps.columnVisibilityModelFirmwaresTable}
			/>
			<DeleteInventoryFirmwaresModal
				showDeleteFirmware={showDeleteFirmware}
				setShowDeleteFirmware={setShowDeleteFirmware}
				technology={technology}
				setSelectionModel={setSelectionModel}
			/>
		</div>
	)
}
