export const DATA_GRID = {
	TEXTS: {
		OVERLAY: {
			NO_ROWS: "No Rows",
			NO_TECHNOLOGY_TITLE: "No data to display",
			NO_TECHNOLOGY_TEXT:
				"Please select the technology in order to be provided with more information."
		}
	},
	STYLES: {
		ROW: {
			MARGIN_LEFT: "10px",
			MARGIN_RIGHT: "10px"
		}
	},
	FIELDS: {
		CUSTOMER_ID: "customerId",
		CUSTOMER_NAME: "customerName",
		FIRMWARE: "firmware",
		FIRMWARE_ID: "firmwareId",
		FIRMWARE_VENDOR: "firmwareVendor",
		FIRMWARE_VERSION: "firmwareVersion",
		FLOOR_PLAN_ID: "floorPlanId",
		FLOOR_PLAN_NAME: "floorPlanName",
		ID: "id",
		MODEL: "model",
		MODEL_ID: "modelId",
		MODEL_VENDOR: "modelVendor",
		NODE_TYPE: "nodeType",
		NODE_ADDRESS: "node_address",
		NOTES: "notes",
		SITE_ID: "siteId",
		SITE_NAME: "siteName",
		STATUS: "status",
		TYPE: "type",
		BATTERY: "battery",
		CHIPSET: "chipset",
		NAME: "name",
		VENDOR: "vendor",
		VERSION: "version",
		SEVERITY: "severity"
	}
}
