export const REPORTING_HEADER = {
	PAGES: {
		PEOPLE_SUMMARY: "peopleSummary",
		PEOPLE_TIMESHEET: "peopleTimesheet",
		ASSETS_SUMMARY: "assetsSummary",
		ASSETS_TIMESHEET: "assetsTimesheet"
	},
	TEXTS: {
		TITLE: "Reporting"
	},
	STYLES: {
		TITLE: {
			COLOR: "#4A4A68"
		},
		SVG: {
			MARGIN: "0 10px 10px 0",
			FONT_SIZE: "24px",
			COLOR: "#2673F0"
		}
	},
	BUTTONS: {
		ARRAY_OF_PEOPLE_BUTTONS: [
			{
				id: "peopleSummary",
				icon: "/media/svg/icons/General/summary.svg",
				label: "People Summary"
			},
			{
				id: "peopleTimesheet",
				icon: "/media/svg/icons/General/timesheet.svg",
				label: "People Timesheet"
			}
		],
		ARRAY_OF_ASSETS_BUTTONS: [
			{
				id: "assetsSummary",
				icon: "/media/svg/icons/General/summary.svg",
				label: "Assets Summary"
			},
			{
				id: "assetsTimesheet",
				icon: "/media/svg/icons/General/timesheet.svg",
				label: "Assets Timesheet"
			}
		]
	}
}
