import React from "react"
import { makeStyles } from "@material-ui/core"

export const useStylesForSidebar = makeStyles(theme => ({
	listRoot: {
		paddingBottom: "0 "
	},
	mainDrawer: {
		background: "#0A1B3D",
		maxWidth: "16%",
		zIndex: 98,
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	mainDrawerClosed: {
		background: "#0A1B3D",
		width: 85,
		overflow: "hidden",
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	nestedDrawer: {
		position: "fixed",
		background: "#DDE9FD",
		left: "16%",
		width: 235,
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	mainDrawerClosedNestedDrawer: {
		position: "fixed",
		background: "#DDE9FD",
		left: 85,
		width: 220,
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	listItem: {
		"&:hover": {
			backgroundColor: "#1A2C4B",
			color: "#FFFFFF"
		},
		"& .MuiListItemText-root": {
			marginLeft: "12px"
		},
		"& .MuiListItemIcon-root": {
			color: "#4A4A68",
			minWidth: "10px"
		}
	},
	nestedListItem: {
		"&:hover": {
			backgroundColor: "#C6DBFD",
			color: "#0A1B3D"
		},
		"& .MuiListItemText-root": {
			marginLeft: "10px",
			color: "#0A1B3D"
		},
		"& .MuiListItemIcon-root": {
			color: "#0A1B3D"
		},
		"& .MuiTypography-body1": {
			fontWeight: "500",
			fontSize: "14px"
		}
	},
	nestedListItemHeader: {
		"& .MuiListItemText-root": {
			color: "#0A1B3D"
		},
		"& .MuiListItemIcon-root": {
			color: "#0A1B3D"
		},
		"& .MuiTypography-body1": {
			fontWeight: "500",
			fontSize: "14px"
		}
	},
	selectedListItem: {
		backgroundColor: "#1A2C4B",
		color: "#FFFFFF"
	},
	selectedNestedListItem: {
		backgroundColor: "#C6DBFD",
		color: "#FFFFFF"
	},
	selectedListIcon: {
		"& .MuiSvgIcon-root": {
			color: "#FFFFFF"
		},
		"& .MuiSvgIcon-root svg": {
			fill: "#2673F0"
		}
	},
	drawerIconSize: {
		"& .MuiSvgIcon-root": {
			width: "1.2em",
			height: "1.2em"
		}
	}
}))
