import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
import { firestore as db } from "../../../../firebase"
import { collection, doc, onSnapshot, query, where, getDocs, Timestamp } from "firebase/firestore"
import { liveDataSlice } from "../../_redux/liveData/liveDataSlice"
import { LiveMapWidget } from "./LiveMap/LiveMapWidget"
import "../../../_assets/sass/pages/dashboard/_dashboard-geral-info-row.scss"
import { TotalRoleOccupancyCard } from "./TotalRoleOccupancy/TotalRoleOccupancyCard"
import { TotalAreaOccupancyCard } from "./TotalZoneOccupancy/TotalAreaOccupancyCard"
import NoSelectedSiteHeader from "./NoSelectedSiteHeader/NoSelectedSiteHeader"
import { TopRowInfo } from "./TopRowInfo/TopRowInfo"
import TopRowInfoForClosedSite from "./TopRowInfoForClosedSite/TopRowInfoForClosedSite"
import { AttendanceListCard } from "./AttendanceList/AttendanceListCard"
import * as liveDataActions from "../../_redux/liveData/liveDataActions"
import { useParams } from "react-router-dom"
import { isEqual, cloneDeep } from "lodash"
import moment from "moment"
import { StickyLiveButton } from "./StickyLiveButton"

export function LiveDataSection() {
	const nodesCollection = global.nodesCollection
	const { id } = useParams()
	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			liveData: UIContext.liveData,
			setLiveData: UIContext.setLiveData,
			resetSnapshot: UIContext.resetSnapshot,
			setResetSnapshot: UIContext.setResetSnapshot
		}
	}, [UIContext])

	//━━━━━━━━━━━━━ Selectors ━━━━━━━━━━━━━\\

	const {
		selectedCustomer,
		selectedSite,
		people,
		assets,
		liveDataTimeoutSeconds,
		tagSeenTimeout,
		activeTags,
		user,
		permissions,
		selectedFloorPlan
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			people: state.basePage?.people,
			assets: state.basePage?.assets,
			liveDataTimeoutSeconds: state.basePage?.globalOptions?.generalInfo?.liveDataTimeoutSeconds,
			tagSeenTimeout: state.basePage?.tagSeenTimeout,
			activeTags: state.liveData?.activeTags,
			user: state.auth?.user,
			permissions: state.auth?.permissions?.permissions,
			selectedFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	//━━━━━━━━━━━━━ States ━━━━━━━━━━━━━\\
	const siteFinished = selectedSite?.siteInfo?.siteFinished || null

	const [newTagsData, setNewTagsData] = useState([])
	const [attendanceListSelectedPerson, setAttendanceListSelectedPerson] = useState(null)
	const [highlightLayerId, setHighlightLayerId] = useState("highlight-layer")
	const [map, setMap] = useState()

	useEffect(() => {
		const element = document.getElementById("dashboard_live_map_widget_card")
		if (element && id) {
			element.scrollIntoView({ behavior: "smooth" })
			const selectedPerson = activeTags.find(tag => tag.person?.id === id)
			if (selectedPerson && map) {
				const layers = map.getStyle()
				if (layers) {
					setAttendanceListSelectedPerson(selectedPerson)
				}
			}
		}
	}, [id, activeTags, map])

	useEffect(() => {
		if (!attendanceListSelectedPerson || !newTagsData.length || !map) return
		const selectedPersonTag = newTagsData.find(tag => tag.id === attendanceListSelectedPerson.id)
		if (selectedPersonTag && map) {
			map.flyTo({
				center: [selectedPersonTag.longitude, selectedPersonTag.latitude],
				zoom: 22
			})

			//This is to make sure this layer doesnt exist before creating a new one
			const layers = map.getStyle()

			if (layers) {
				typeof map.getLayer("goToThingMarker") !== "undefined" &&
					map.removeLayer("goToThingMarker")
				typeof map.getSource("goToThingMarker") !== "undefined" &&
					map.removeSource("goToThingMarker")

				typeof map.getLayer("goToThingMarker") !== "undefined" &&
					map.removeLayer("goToThingMarker")
				typeof map.getSource("goToThingMarker") !== "undefined" &&
					map.removeSource("goToThingMarker")

				map.addSource("goToThingMarker", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: [
							{
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: [selectedPersonTag.longitude, selectedPersonTag.latitude]
								}
							}
						]
					}
				})
				map.addLayer(
					{
						id: "goToThingMarker",
						type: "circle",
						source: "goToThingMarker",
						paint: {
							"circle-color": "#4EECC8",
							"circle-radius": 20,
							"circle-stroke-width": 0, // ← circle border
							"circle-stroke-color": "#ffffff"
						}
					},
					"settlement-label"
				)
			}
		}
	}, [attendanceListSelectedPerson, newTagsData])

	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		UIProps.setLiveData(true)

		// Query for nodes of type "tag"
		const nodesRef = collection(doc(db, "Sites", selectedSite.id), nodesCollection)
		const nodesQuery = query(nodesRef, where("nodeType", "==", "tag"))

		const unsubscribeNodes = onSnapshot(
			nodesQuery,
			snapshot => {
				const newTags = snapshot.docs.map(doc => {
					const data = doc.data()

					// Transform lastSeen property if it is a string to Timestamp
					if (typeof data.lastSeen === "string") {
						data.lastSeen = new Date(data.lastSeen)
					}

					return { id: doc.id, ...data }
				})

				setNewTagsData(newTags)
			},
			error => console.error("Error fetching nodes:", error)
		)

		// Query for weather data if cityDocId is available
		let unsubscribeWeather
		if (selectedSite.siteInfo?.cityDocId) {
			const weatherRef = doc(db, "Weather", selectedSite.siteInfo.cityDocId)

			unsubscribeWeather = onSnapshot(
				weatherRef,
				doc => {
					if (!doc) {
						dispatch(liveDataSlice.actions.weatherFeched())
						return
					}
					const data = { id: doc.id, ...doc.data() }
					dispatch(liveDataSlice.actions.weatherFeched(data))
				},
				error => console.error("Error fetching weather:", error)
			)
		} else {
			dispatch(liveDataSlice.actions.weatherFeched())
		}

		// Set a timeout to stop live data updates
		const timer = setTimeout(
			() => {
				UIProps.setLiveData(false)
				unsubscribeNodes()
				if (unsubscribeWeather) unsubscribeWeather()
			},
			liveDataTimeoutSeconds ? liveDataTimeoutSeconds * 1000 : 600000
		)

		// Cleanup function
		return () => {
			unsubscribeNodes()
			if (unsubscribeWeather) unsubscribeWeather()
			clearTimeout(timer)
		}
	}, [selectedCustomer, selectedSite, UIProps.resetSnapshot, liveDataTimeoutSeconds, dispatch])

	useEffect(() => {
		if (!selectedSite?.id || !selectedFloorPlan?.id) return

		// Dispatch actions for fetching POIs and icons
		dispatch(
			liveDataActions.fetchPois({
				siteId: selectedSite.id
			})
		)
		dispatch(liveDataActions.fetchIconsFromDB())

		// Fetch anchors only if they haven't been fetched yet
		const fetchAnchors = async () => {
			try {
				const q = query(
					collection(db, `Sites/${selectedSite?.id}/${nodesCollection}`),
					where("floorPlanId", "==", selectedFloorPlan?.id),
					where("nodeType", "==", "anchor")
				)

				const snapshot = await getDocs(q)

				const newAnchors = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}))

				if (newAnchors && newAnchors.length > 0) {
					dispatch(liveDataSlice.actions.addAnchors(newAnchors))
				}
			} catch (error) {
				console.error("Error fetching anchors:", error)
			}
		}

		fetchAnchors() // Call the async function
		// }
	}, [selectedSite, selectedFloorPlan]) // Ensure proper dependencies

	// ━━━━━━━━━━ Fetch all Areas
	useEffect(() => {
		if (!selectedSite) return

		dispatch(
			liveDataActions.fetchAreasInDB({
				siteId: selectedSite.id
			})
		)
	}, [selectedSite])

	// ━━━━━━━━━━ Fetch user drawer preferences
	useEffect(() => {
		if (!selectedSite && !user) return

		user?.id &&
			dispatch(
				liveDataActions.fetchUserPreferencesInDB({
					userId: user?.id
				})
			)
	}, [selectedSite, user])

	// Add the person assigned to the tag directly to it
	// And filter active tags to separate all from active
	// This helps reduce renders and filters/finds
	useEffect(() => {
		if (!people || !newTagsData) {
			// dispatch(liveDataSlice.actions.tagsWithPeopleFetched(newTagsData)) // if !newTagsData dispatch empty array to redux and return
			return
		}
		const newTags = newTagsData
			.filter(t => {
				if (!t?.firstSeen || !t?.lastSeen) {
					return false
				}

				let firstSeenDate
				let lastSeenDate

				if (t.firstSeen instanceof Timestamp) {
					firstSeenDate = t.firstSeen.toDate()
				} else if (typeof t.firstSeen === "string") {
					firstSeenDate = new Date(t.firstSeen)
				} else {
					return false
				}

				if (t.lastSeen instanceof Timestamp) {
					lastSeenDate = t.lastSeen.toDate()
				} else if (typeof t.lastSeen === "string") {
					lastSeenDate = new Date(t.lastSeen)
				} else {
					return false
				}

				// Filter tags if the firstSeen and lastSeen have a difference greater than 5m
				return moment(lastSeenDate).diff(moment(firstSeenDate), "minutes") >= 5
			})
			.map(tag => {
				const person = people && people.find(val => val.uuid === tag.uuid)

				var updatedTag = cloneDeep(tag)
				if (person) {
					updatedTag.person = person
				} else {
					updatedTag.person = null
				}

				const asset = assets && assets.find(val => val.uuid === tag.uuid)
				if (asset && !person) {
					updatedTag.asset = asset
				} else {
					updatedTag.asset = null
				}

				if (tag.lastSeen) {
					const lastSeen = moment(tag.lastSeen.toDate())
					const xTimeAgo = moment(Timestamp.now().toDate()).subtract(tagSeenTimeout, "seconds")
					if (lastSeen.isAfter(xTimeAgo)) {
						updatedTag.tagState = "active"
					} else {
						updatedTag.tagState = "inactive"
					}
				}

				return updatedTag
			})
		dispatch(liveDataSlice.actions.tagsFetched(newTags))
		// This was created due to tagsFetched being update 2 time,
		// one on snapshot and the other when adding people to those tags
		// Like this we onlu update tagsWithPeopleFetched once after tags are update and we add people

		// add to remove because like this if the values become zero it doenst update
		// also, i think tagsWithPeopleFetched or this is used globaly or has it is we should remove it
		dispatch(liveDataSlice.actions.tagsWithPeopleFetched(newTags))
		// }

		const newActiveTags = newTags.filter(tag => tag.tagState === "active")

		if (!isEqual(activeTags, newActiveTags)) {
			dispatch(liveDataSlice.actions.activeTagsFetched(newActiveTags))
		}
	}, [people, assets, newTagsData])

	return (
		<>
			{selectedSite &&
				!siteFinished &&
				permissions &&
				permissions.Dashboard?.PeopleAndAssets?.TopRowInfo && (
					<div className="col-12" style={{ marginBottom: "2rem" }}>
						<TopRowInfo />
					</div>
				)}

			{selectedSite && siteFinished && (
				<div className="col-12" style={{ marginBottom: "2rem" }}>
					<TopRowInfoForClosedSite />
				</div>
			)}

			{!selectedSite && (
				<div className="col-12" style={{ marginBottom: "10rem" }}>
					<NoSelectedSiteHeader />
				</div>
			)}

			{permissions && permissions.Dashboard?.PeopleAndAssets?.Map && (
				<div className="col-12">
					<LiveMapWidget map={map} setMap={setMap} />
				</div>
			)}

			{selectedSite && !siteFinished && (
				<>
					{selectedSite &&
						permissions &&
						permissions.Dashboard?.PeopleAndAssets?.PeopleDistribution && (
							<TotalAreaOccupancyCard />
						)}

					{selectedSite &&
						permissions &&
						permissions.Dashboard?.PeopleAndAssets?.GeneralAttendance && (
							<TotalRoleOccupancyCard />
						)}

					{selectedSite &&
						permissions &&
						permissions.Dashboard?.PeopleAndAssets?.AttendanceList && (
							<div className="col-12">
								<AttendanceListCard
									attendanceListSelectedPerson={attendanceListSelectedPerson}
									setAttendanceListSelectedPerson={setAttendanceListSelectedPerson}
								/>
							</div>
						)}

					<StickyLiveButton />
				</>
			)}
		</>
	)
}
