/* eslint-disable no-unused-vars */
const prodConfig = {
	production: "production",
	apiKey: "AIzaSyBe5_69FCOiMeWr6yfppRbJWr-OrRjzK-M",
	authDomain: "crowdkeep-saas.firebaseapp.com",
	projectId: "crowdkeep-saas",
	storageBucket: "crowdkeep-saas.appspot.com",
	messagingSenderId: "792085647590",
	appId: "1:792085647590:web:0a2bedb7679dfccaa06b27",
	measurementId: "G-18PX660BMR"
}
const demoConfig = {
	production: "demo",
	apiKey: "AIzaSyDncHywYqEu1YjMpviGhqrTEI2xTpLPEvQ",
	authDomain: "ckconstruction-aa252.firebaseapp.com",
	projectId: "ckconstruction-aa252",
	storageBucket: "ckconstruction-aa252.appspot.com",
	messagingSenderId: "920021108967",
	appId: "1:920021108967:web:6cb6ae448db0be844efa42",
	measurementId: "G-R2Y3C0WS14"
}
// const stagingConfig = {
// 	production: "staging",
// 	apiKey: "AIzaSyAJVcSN0DV38ibPbhfeOQkdFywvGRQVhwc",
// 	authDomain: "ckconstruction-staging.firebaseapp.com",
// 	projectId: "ckconstruction-staging",
// 	storageBucket: "ckconstruction-staging.appspot.com",
// 	messagingSenderId: "627792258607",
// 	appId: "1:627792258607:web:00aa5e62b09f9a19f888da",
// 	measurementId: "G-WBZYGE95EH"
// }
const devConfig = {
	production: "development",
	apiKey: "AIzaSyDDeGnxc0AHqxHWKvyIQKYT8rVu-XeGtEk",
	authDomain: "ckconstruction-dev.firebaseapp.com",
	projectId: "ckconstruction-dev",
	storageBucket: "ckconstruction-dev.appspot.com",
	messagingSenderId: "965199018917",
	appId: "1:965199018917:web:d5bb48a3fbb406c426464c",
	measurementId: "G-8SPE5KR741"
}
export default demoConfig
