import React from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import SVG from "react-inlinesvg"

import "./Header.css"
import HeaderDropdownOnMobile from "./HeaderDropdownOnMobile"
import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers"
import { ROUTES } from "../_enums/inventory"

const MODELS = "Models"
const FIRMWARE = "Firmware"
const OPERATIONS = "Operations"
const NODES = "Nodes"

export function Header() {
	const location = useLocation()

	const getMenuItemActive = url => {
		return checkIsActive(location, url) ? true : false
	}

	return (
		<div style={{ marginBottom: "30px" }}>
			<div className="row justify-content-center">
				{/* header title */}
				<div className="col d-flex align-items-center">
					<div
						style={{
							width: "30px",
							height: "30px",
							marginRight: "10px",
							marginBottom: "10px",
							alignContent: "center"
						}}
					>
						<SVG
							src={toAbsoluteUrl("/media/svg/icons/General/operations-icon.svg")}
							style={{
								height: "2rem",
								width: "2rem",
								fill: "#3077d3"
							}}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>{OPERATIONS}</b>
					</h2>
				</div>

				{/* desktop menu */}
				<div className="col-sm d-none d-sm-flex justify-content-end">
					<div className="row">
						<div
							className="col-sm"
							style={{
								textAlign: "center",
								borderBottom: getMenuItemActive(ROUTES.NODES_ROUTE)
									? "4px solid #2673F0"
									: "none",
								padding: "10px"
							}}
						>
							<NavLink className="operations-menu-link" to={ROUTES.NODES_ROUTE}>
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Nodes.svg")}
									style={{
										height: "2rem",
										width: "2rem",
										fill: getMenuItemActive(ROUTES.NODES_ROUTE) ? "#2673F0" : "#4A4A68",
										marginBottom: "5px"
									}}
								/>
								<span
									className="header-menu-text"
									style={{
										color: getMenuItemActive(ROUTES.NODES_ROUTE) ? "#2673F0" : "#4A4A68"
									}}
								>
									{NODES}
								</span>
							</NavLink>
						</div>
						<div
							className="col-sm"
							style={{
								textAlign: "center",
								borderBottom: getMenuItemActive(ROUTES.MODELS_ROUTE)
									? "4px solid #2673F0"
									: "none",
								padding: "10px"
							}}
						>
							<NavLink className="operations-menu-link" to={ROUTES.MODELS_ROUTE}>
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Models.svg")}
									style={{
										height: "2rem",
										width: "2rem",
										fill: getMenuItemActive(ROUTES.MODELS_ROUTE) ? "#2673F0" : "#4A4A68",
										marginBottom: "5px"
									}}
								/>
								<br />
								<span
									className="header-menu-text"
									style={{
										color: getMenuItemActive(ROUTES.MODELS_ROUTE) ? "#2673F0" : "#4A4A68"
									}}
								>
									{MODELS}
								</span>
							</NavLink>
						</div>
						<div
							className="col-sm"
							style={{
								textAlign: "center",
								borderBottom: getMenuItemActive(ROUTES.FIRMWARES_ROUTE)
									? "4px solid #2673F0"
									: "none",
								padding: "10px"
							}}
						>
							<NavLink className="operations-menu-link" to={ROUTES.FIRMWARES_ROUTE}>
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Firmware.svg")}
									style={{
										height: "2rem",
										width: "2rem",
										fill: getMenuItemActive(ROUTES.FIRMWARES_ROUTE)
											? "#2673F0"
											: "#4A4A68",
										marginBottom: "5px"
									}}
								/>
								<br />
								<span
									className="header-menu-text"
									style={{
										color: getMenuItemActive(ROUTES.FIRMWARES_ROUTE)
											? "#2673F0"
											: "#4A4A68"
									}}
								>
									{FIRMWARE}
								</span>
							</NavLink>
						</div>
					</div>
				</div>

				{/* mobile menu */}
				<div className="d-flex d-sm-none justify-content-center m-5">
					<div className="header_dropdown_mobile_view">
						<HeaderDropdownOnMobile />
					</div>
				</div>
			</div>
		</div>
	)
}
