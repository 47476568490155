export const BUTTONS = {
	COLORS: {
		PRIMARY: "primary",
		PRIMARY_LIGHT: "primaryLight",
		GREY_LIGHT: "greyLight"
	},
	VARIANTS: {
		CONTAINED: "contained",
		OUTLINED: "outlined"
	}
}
