import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { TotalRoleOccupancyGraph } from "./TotalRoleOccupancyGraph"
import { Button } from "../../../../_partials/Button"

export function TotalRoleOccupancyCard() {
	const { tags, companies } = useSelector(
		state => ({
			tags: state.liveData?.tagsWithPeople,
			companies: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	// useState
	const [graphData, setGraphData] = useState({ series: [], labels: [] })

	useEffect(() => {
		if (!tags || !companies || companies.length === 0) return

		const tagsWithPeople = tags
			.map(val => {
				if (!val.person) return
				return val
			})
			.filter(val => val)

		// Total Role Occupancy
		const labels = []

		// add graph labels array
		tagsWithPeople &&
			tagsWithPeople.forEach(val => {
				let role = val.person.role || "No role"
				if (labels.some(value => value === role)) return
				labels.push(role)
			})

		// add peopleOnSite and absentPeople count logic
		let peopleOnSite = []
		let absentPeople = []
		labels.forEach(val => {
			peopleOnSite.push({ role: val, counter: 0 })
			absentPeople.push({ role: val, counter: 0 })
		})

		tagsWithPeople &&
			tagsWithPeople.forEach(val => {
				const index = labels.findIndex(value => value === (val.person.role || "No role"))

				if (val.tagState === "inactive") {
					absentPeople[index].counter = absentPeople[index].counter + 1
				} else {
					peopleOnSite[index].counter = peopleOnSite[index]?.counter + 1
				}
			})

		// Combine peopleOnSite and absentPeople on same array
		const combinedData = peopleOnSite.map((value, index) => ({
			role: value.role,
			onSite: value.counter,
			absent: absentPeople[index].counter
		}))

		// Sort combinedData based on 'onSite' count in descending order
		const sortedData = combinedData.sort((a, b) => b.onSite - a.onSite)

		// Filter non-zero values
		const filteredData = sortedData.filter(entry => entry.onSite !== 0)

		// Extract filtered labels and series
		const filteredLabels = filteredData.map(entry => entry.role)

		const filteredOnSiteSeries = filteredData.map(entry => entry.onSite)

		const filteredAbsentSeries = filteredData.map(entry => entry.absent)

		setGraphData({
			labels: filteredLabels || [],
			series: [
				{
					name: "On-site people",
					data: filteredOnSiteSeries || []
				},
				{
					name: "Off-site people",
					data: filteredAbsentSeries || []
				}
			]
		})
	}, [tags, companies])

	if (!graphData?.series || graphData?.series?.length === 0) return <div></div>

	return (
		<div className="col-4">
			<Card>
				<CardHeader
					title={"General Attendance"}
					subtitle="Daily percentage of people at the hospital"
					line={false}
				/>
				<CardBody padding="13px" style={{ height: "385px" }}>
					<TotalRoleOccupancyGraph graphData={graphData} />
				</CardBody>
			</Card>
		</div>
	)
}
