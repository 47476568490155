import React, { useCallback, useState } from "react"
import { Button } from "../../../_partials/Button"
import { makeStyles } from "@material-ui/core"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { Input } from "../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import { useSelector, shallowEqual, useDispatch } from "react-redux"

const EditSchema = Yup.object().shape({
	subject: Yup.string().nullable().trim().min(1, "Subject must not be empty"),
	message: Yup.string().nullable().trim().min(1, "Message must not be empty")
})
const initialValues = {
	subject: "",
	message: ""
}

const useStyles = makeStyles(theme => ({
	title: {
		color: "#444",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "600",
		lineHeight: "160%",
		textTransform: "capitalize",
		padding: "1rem 0"
	},
	description: {
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	container: {
		padding: "1rem",
		backgroundColor: "#FFFFFF",
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))"
	},
	buttonContainer: {
		// padding: "1rem 1rem 0 1rem"
		padding: "0 1rem 0 1rem"
	},
	toggleButton: {
		display: "flex !important",
		padding: "10px 20px !important",
		justifyContent: "center !important",
		alignItems: "flex-start !important",
		gap: "10px !important",
		flex: "1 0 0 !important",
		borderRadius: "4px !important",
		border: "none !important",

		fontFamily: "Poppins !important",
		fontSize: "16px !important",
		fontStyle: "normal !important",
		fontWeight: "700 !important",
		lineHeight: "normal !important",
		letterSpacing: "0.64px !important",
		textTransform: "uppercase !important"
	},
	normalPriority: { color: "#53B04F !important", backgroundColor: "#E6F4E6 !important" },
	normalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#53B04F !important"
	},
	mediumPriority: {
		color: "#F6B952 !important",
		backgroundColor: "#FDF1DC !important",
		margin: "0 10px !important"
	},
	mediumPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#F6B952 !important",
		margin: "0 10px !important"
	},
	criticalPriority: {
		color: "#E65019 !important",
		backgroundColor: "#FBE6DE !important"
	},
	criticalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#E65019 !important"
	},
	selectedApp: {
		borderBottom: "1px solid #2673F0",
		fontWeight: "700"
	},
	selectedSms: {
		borderBottom: "1px solid #8680FF",
		fontWeight: "700"
	},
	switchSms: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#8680FF !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#cfccff !important"
		}
	},
	switchApp: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#2673F0 !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#a8c7f9 !important"
		}
	}
}))

const CustomToggleButton = ({ alignment, value }) => {
	const classes = useStyles()
	const isSelected = alignment === value

	return (
		<ToggleButton
			className={`${classes.toggleButton} 
        ${
				value === "normal"
					? isSelected
						? classes.normalPrioritySelected
						: classes.normalPriority
					: ""
			} 
        ${
				value === "medium"
					? isSelected
						? classes.mediumPrioritySelected
						: classes.mediumPriority
					: ""
			} 
        ${
				value === "critical"
					? isSelected
						? classes.criticalPrioritySelected
						: classes.criticalPriority
					: ""
			}
      `}
			value={value}
		>
			{value}
		</ToggleButton>
	)
}

const AnnouncementsPage = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { selectedCustomer, user } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			user: state.auth?.user
		}),
		shallowEqual
	)

	const [alignment, setAlignment] = useState("normal")
	const [inputMessage, setInputMessage] = useState("")
	const [inputSubject, setInputSubject] = useState("")
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [failure, setFailure] = useState(false)

	const handleButtonGroupChange = useCallback((event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment)
		}
	}, [])

	return (
		<div className={classes.container} data-testid="send-message-component">
			<div className="row">
				<div className="col">
					<div className="row m-0 justify-content-between">
						<div className={`${classes.title} col`}>Announcement</div>
					</div>
					<div className={classes.description}>
						Announcement will be sent out to all active people today.
					</div>

					<div className={classes.label}>Priority Level</div>
					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleButtonGroupChange}
						aria-label="Priority Level"
						fullWidth
						// style={{ width: "100%" }}
					>
						<CustomToggleButton value="normal" alignment={alignment} />
						<CustomToggleButton value="medium" alignment={alignment} />
						<CustomToggleButton value="critical" alignment={alignment} />
					</ToggleButtonGroup>

					{/* <div className={classes.label}>Subject</div> */}
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={EditSchema}
						data-testid="NewNodeModel"
					>
						{({ setFieldValue }) => {
							return (
								<Form className="form form-label-right p-3" data-testid="NewNodeModel">
									<div className="form-group row mb-0 mt-2">
										<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
											<div className={classes.label}>
												Subject <sup style={{ color: "red" }}> *</sup>
											</div>
										</div>
										<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
											<Field
												variant="filled"
												name="subject"
												placeholder="Enter subject"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												value={inputSubject}
												onChange={e => {
													if (!e.target.value) {
														setInputSubject("")
														setFieldValue("subject", "")
													} else {
														setInputSubject(e.target.value)
														setFieldValue("subject", e.target.value)
													}
												}}
											/>
										</div>
									</div>
									<div className="form-group row mb-0">
										<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
											<div className={classes.label}>
												Message <sup style={{ color: "red" }}> *</sup>
											</div>
										</div>
										<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
											<Field
												variant="filled"
												name="message"
												placeholder="Write a message..."
												multiline
												rows={6} // Adjust the number of rows as needed
												component={Input}
												InputProps={{
													disableUnderline: true,
													style: { height: "auto", padding: "12px" }
												}}
												value={inputMessage}
												onChange={e => {
													if (!e.target.value) {
														setInputMessage("")
														setFieldValue("message", "")
													} else {
														setInputMessage(e.target.value)
														setFieldValue("message", e.target.value)
													}
												}}
												data-testid="Formik_Form_Notes"
											/>
										</div>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
			<div className="row justify-content-end" style={{ padding: "0 1rem 0 1rem" }}>
				<Button
					color="primary"
					variant="contained"
					disabled={!inputSubject.trim() || !inputMessage.trim()}
					onClick={() => {
						setLoading(true)
						handleBroadcast(
							selectedCustomer,
							user,
							inputMessage,
							inputSubject,
							alignment,
							setSuccess,
							setFailure,
							setLoading,
							dispatch
						)
					}}
					text="SEND"
					needsLoadingBar
					loading={loading}
					success={success}
					failure={failure}
					setSuccess={setSuccess}
					setFailure={setFailure}
				/>
			</div>
		</div>
	)
}

export default AnnouncementsPage

async function handleBroadcast(
	selectedCustomer,
	user,
	inputMessage,
	inputSubject,
	alignment,
	setSuccess,
	setFailure,
	setLoading,
	dispatch
) {
	try {
		setLoading(true)
		const result = await sendViaAPP({
			inputMessage,
			inputSubject,
			alignment,
			user,
			customerId: selectedCustomer.id
		})
		setLoading(false)

		if (result && result > 0) {
			setSuccess(true)
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Message has been sent successfully (${result}).` }
			})
		} else {
			throw new Error(`Unexpected result (${result}).`)
		}
	} catch (error) {
		setLoading(false)
		setFailure(true)
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: { message: `Failed to send the message: ${error.message}` }
		})
	}
}

async function sendViaAPP({ inputMessage, inputSubject, alignment, user, customerId }) {
	var promises = []

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify({
		type: "announcement",
		subject: inputSubject,
		body: inputMessage,
		severity: alignment === "critical" ? 3 : alignment === "medium" ? 2 : 1,
		uidFrom: user.id
	})

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	promises.push(
		fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/send-notification`, requestOptions)
	)

	await Promise.all(promises)
	return promises.length
}
