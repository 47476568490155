import React from "react"
import { SIDEBAR } from "../../_enums/sidebar"
import { ListItem, ListItemText } from "@material-ui/core"

export const NestedListItem = ({
	nestedOption,
	handleNestedOptionClick,
	selectedOption,
	classes,
	shouldHaveBullet = false
}) => {
	return (
		<ListItem
			button
			onClick={() => handleNestedOptionClick(nestedOption.redirectTo)}
			className={
				selectedOption === nestedOption.redirectTo
					? `${classes.nestedListItem} ${classes.selectedNestedListItem}`
					: classes.nestedListItem
			}
		>
			<ListItemText
				primary={nestedOption.title}
				className={classes.listItemText}
				style={
					!shouldHaveBullet
						? null
						: {
								display: SIDEBAR.STYLES.NESTED_LIST_ITEM.DISPLAY_LIST_ITEM,
								listStyleType: SIDEBAR.STYLES.NESTED_LIST_ITEM.LIST_STYLE_TYPE_DISC,
								marginLeft: SIDEBAR.STYLES.NESTED_LIST_ITEM.MARGIN_LEFT
						  }
				}
			/>
		</ListItem>
	)
}
