import React from "react"
import Chart from "react-apexcharts"

export function TotalRoleOccupancyGraph({ graphData }) {
	return (
		<>
			<Chart
				options={getChartOptions(graphData)}
				series={graphData?.series[0].data} // Summing values for display
				type="donut"
				height="100%"
			/>
		</>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			type: "donut",
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			pie: {
				donut: {
					size: "60%",
					labels: {
						show: true,
						total: {
							show: true,
							label: graphData.series[0].data.reduce((acc, curr) => acc + curr, 0),
							formatter: () => "Total people",
							style: {
								// Use the style property
								fontSize: "10px",
								fontFamily: "Poppins",
								fontWeight: 200,
								color: "#4A4A68"
							},
							offsetY: 0
						}
					}
				}
			}
		},
		dataLabels: {
			enabled: true, // Enable data labels
			formatter: function (val) {
				return `${val.toFixed(0)}%` // Display value and percentage
			},
			style: {
				fontSize: "10px",
				fontFamily: "Poppins",
				fontWeight: 200,
				color: "#4A4A68"
			}
		},
		colors: ["#244C22", "#3B7E38", "#53B04F", "#B5DDB3", "#E6F4E6"],
		stroke: {
			show: true,
			width: 5,
			colors: ["white"]
		},
		legend: {
			show: true,
			position: "bottom"
		},
		labels: graphData?.labels,
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	}
}
