import React, { useEffect } from "react"
import { NewGroupModalTabs } from "./NewGroup/NewGroupModalTabs"
import { useSelector, shallowEqual, useDispatch } from "react-redux"

import * as actions from "../_redux/manageGroups/manageGroupsActions"
import * as manageSitesActions from "../_redux/manageSites/manageSitesActions"

export function CommunicationCard({ onHide }) {
	const dispatch = useDispatch()

	const { currentCustomer, selectedSite, allGroups, allPeople, allVendors, user, permissions } =
		useSelector(
			state => ({
				currentCustomer: state?.profile?.currentCustomer,
				selectedSite: state?.profile?.currentSite,
				allGroups: state.manageGroups.allGroups,
				allPeople: state.manageGroups.allPeople,
				allvendors: state.profile?.currentCustomer?.vendors || [],
				user: state.auth?.user,
				permissions: state.auth?.permissions?.permissions
			}),
			shallowEqual
		)

	//━━━━━━━━━━━━━━━ useEffect ━━━━━━━━━━━━━━━\\
	useEffect(() => {
		if (!selectedSite || !currentCustomer) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: `No location selected. Please select a location on top.` }
			})
			return
		}

		dispatch(
			manageSitesActions.fetchSites({
				customerId: currentCustomer.id
			})
		)

		dispatch(
			actions.fetchAllGroups({
				customerId: currentCustomer.id,
				userId: user.id
			})
		)

		if (allPeople && allPeople.length > 0) return
		dispatch(
			actions.fetchCustomerPeople({
				customerId: currentCustomer.id
			})
		)
	}, [currentCustomer, selectedSite, user])

	//━━━━━━━━━━━━━━━ Handlers ━━━━━━━━━━━━━━━\\
	function hide() {
		onHide()
	}

	return (
		<div data-testid="communication-card">
			<NewGroupModalTabs
				onHide={hide}
				allGroups={allGroups}
				allVendors={allVendors}
				allPeople={allPeople}
			/>
		</div>
	)
}

export default CommunicationCard
