import React, { useState, memo } from "react"
import { NestedListItem } from "./NestedListItem"
import Drawer from "../../_partials/Drawer"
import { SIDEBAR } from "../../_enums/sidebar"
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	IconButton,
	Collapse,
	SvgIcon
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const MemoizedListItem = memo(ListItem)
const MemoizedListItemIcon = memo(ListItemIcon)
const MemoizedListItemText = memo(ListItemText)

const NestedDrawer = ({
	isNestedDrawerOpen,
	setNestedDrawerOpen,
	currentNestedOptions,
	selectedOption,
	setSelectedOption,
	selectedNestedOption,
	mainDrawerOpened,
	handleHideDrawer,
	matches,
	history,
	classes
}) => {
	const [open, setOpen] = useState(false)

	const handleClick = () => {
		setOpen(!open)
	}

	const handleNestedOptionClick = redirectTo => {
		setSelectedOption(redirectTo)
		setNestedDrawerOpen(false)
		history.push(redirectTo)
		!matches && handleHideDrawer()
	}

	const toggleNestedDrawer = () => {
		setNestedDrawerOpen(!isNestedDrawerOpen)
	}

	const renderNestedOptions = nestedOption => {
		if (nestedOption.nestedOptions) {
			return (
				<List
					component={SIDEBAR.STYLES.NESTED_LIST_OPTION.RENDER_COMPONENT_AS_DIV}
					disablePadding
					className={classes.listRoot}
				>
					{nestedOption.nestedOptions.map((nestedOption, nestedIndex) => (
						<NestedListItem
							key={nestedIndex}
							nestedOption={nestedOption}
							handleNestedOptionClick={handleNestedOptionClick}
							selectedOption={selectedOption}
							classes={classes}
							shouldHaveBullet={true}
						/>
					))}
				</List>
			)
		}
		return null
	}

	return (
		<Drawer
			anchor={SIDEBAR.STYLES.DRAWER.ANCHOR_LEFT}
			open={isNestedDrawerOpen}
			onClose={toggleNestedDrawer}
			classes={{
				paper:
					matches && mainDrawerOpened
						? classes.nestedDrawer
						: classes.mainDrawerClosedNestedDrawer
			}}
		>
			<IconButton
				onClick={toggleNestedDrawer}
				style={{ alignSelf: SIDEBAR.STYLES.ICONS.ALIGN_SELF_END }}
			>
				<ArrowIcon />
			</IconButton>

			<List>
				{mainDrawerOpened && selectedNestedOption && (
					<MemoizedListItem className={classes.nestedListItemHeader}>
						<MemoizedListItemIcon
							className={`${classes.selectedListIcon} ${classes.drawerIconSize}`}
							style={{
								placeContent: SIDEBAR.STYLES.LIST_ITEM.PLACE_CONTENT_CENTER,
								marginTop: SIDEBAR.STYLES.LIST_ITEM.MARGIN_TOP
							}}
						>
							{selectedNestedOption.label.icon}
						</MemoizedListItemIcon>
						<MemoizedListItemText
							primary={selectedNestedOption.label.title}
							className={classes.listItemText}
						/>
					</MemoizedListItem>
				)}

				{currentNestedOptions.map((nestedOption, index) => {
					if (nestedOption.nestedOptions) {
						return (
							<React.Fragment key={nestedOption.title}>
								<MemoizedListItem
									button
									onClick={handleClick}
									className={
										selectedOption === nestedOption.redirectTo
											? `${classes.nestedListItem} ${classes.selectedNestedListItem}`
											: classes.nestedListItem
									}
								>
									<MemoizedListItemText
										primary={nestedOption.title}
										className={classes.listItemText}
									/>
									<MemoizedListItemIcon
										style={{
											placeContent: SIDEBAR.STYLES.LIST_ITEM.ICON.PLACE_CONTENT_END
										}}
									>
										{!open ? (
											<ExpandMore fontSize={SIDEBAR.STYLES.ICONS.FONT_SIZE_LARGE} />
										) : (
											<ExpandLess fontSize={SIDEBAR.STYLES.ICONS.FONT_SIZE_LARGE} />
										)}
									</MemoizedListItemIcon>
								</MemoizedListItem>
								<Collapse
									in={open}
									timeout={SIDEBAR.STYLES.COLLAPSE.TIMEOUT_AUTO}
									unmountOnExit
								>
									{renderNestedOptions(nestedOption)}
								</Collapse>
							</React.Fragment>
						)
					}

					return (
						<NestedListItem
							key={nestedOption.title}
							nestedOption={nestedOption}
							handleNestedOptionClick={handleNestedOptionClick}
							selectedOption={selectedOption}
							classes={classes}
						/>
					)
				})}
			</List>
		</Drawer>
	)
}

export default NestedDrawer

// after Feature/CKC-2925 PR is approved, change this to use dynamic icon render function
function ArrowIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.5893 5.5886C10.9147 5.26317 10.9147 4.73553 10.5893 4.41009C10.2638 4.08466 9.73618 4.08466 9.41074 4.41009L4.41074 9.41009C4.09526 9.72558 4.08422 10.2336 4.3857 10.5625L8.96903 15.5625C9.28003 15.9017 9.80717 15.9246 10.1464 15.6136C10.4857 15.3027 10.5086 14.7755 10.1976 14.4362L6.15344 10.0244L10.5893 5.5886Z"
					fill="#1C1C3B"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.4226 5.5886C16.748 5.26317 16.748 4.73553 16.4226 4.41009C16.0972 4.08466 15.5695 4.08466 15.2441 4.41009L10.2441 9.41009C9.9286 9.72558 9.91756 10.2336 10.219 10.5625L14.8024 15.5625C15.1134 15.9017 15.6405 15.9246 15.9798 15.6136C16.319 15.3027 16.342 14.7755 16.031 14.4362L11.9868 10.0244L16.4226 5.5886Z"
					fill="#1C1C3B"
				/>
			</svg>
		</SvgIcon>
	)
}
