import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import { useSelector, shallowEqual } from "react-redux"
import { UIProvider } from "./UIContext"
import DataUsageIcon from "@material-ui/icons/DataUsage"
import { DataFilter } from "./data-filter/DataFilter"
import { PeopleTimesheetPage } from "./PeopleTimesheet/PeopleTimesheetPage"
import { PeopleSummaryPage } from "./PeopleSummary/PeopleSummaryPage"
import "./ReportingPage.css"
import { REPORTING_HEADER } from "./_enums/header"
import DoubleRouteButton from "../../_partials/DoubleRouteButton"

export const ReportingPage = () => {
	const { selectedSite, permissions } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			permissions: state.auth?.permissions?.permissions
		}),
		shallowEqual
	)

	const [page, setPage] = useState(REPORTING_HEADER.PAGES.PEOPLE_SUMMARY)

	const buttons = REPORTING_HEADER.BUTTONS.ARRAY_OF_PEOPLE_BUTTONS

	return (
		<UIProvider>
			<div className="row ml-auto mr-auto mb-10">
				<div className="col d-flex align-items-center">
					<div style={{ margin: REPORTING_HEADER.STYLES.SVG.MARGIN }}>
						<DataUsageIcon
							style={{
								fontSize: REPORTING_HEADER.STYLES.SVG.FONT_SIZE,
								color: REPORTING_HEADER.STYLES.SVG.COLOR
							}}
						/>
					</div>

					<h2 style={{ color: REPORTING_HEADER.STYLES.TITLE.COLOR }}>
						<b>{REPORTING_HEADER.TEXTS.TITLE}</b>
					</h2>
				</div>
				<div className="d-none d-sm-flex">
					<DoubleRouteButton
						buttons={buttons}
						activeId={page}
						onButtonClick={id => setPage(id)}
					/>
				</div>
			</div>
			{!selectedSite && <Redirect exact={true} to="/dashboard" />}
			{selectedSite && <DataFilter />}
			{selectedSite &&
				page === REPORTING_HEADER.PAGES.PEOPLE_SUMMARY &&
				permissions.Reporting.People.Summary && <PeopleSummaryPage />}
			{selectedSite &&
				page === REPORTING_HEADER.PAGES.PEOPLE_TIMESHEET &&
				permissions.Reporting.People.Timesheets && <PeopleTimesheetPage />}
		</UIProvider>
	)
}
