import React from "react"
import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector
} from "@mui/x-data-grid"

export function QuickToolbar(props) {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarDensitySelector />
			</GridToolbarContainer>
		</div>
	)
}
