import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { TotalAreaOccupancyGraph } from "./TotalAreaOccupancyGraph"
import { Button } from "../../../../_partials/Button"
import { useSelector, shallowEqual } from "react-redux"

import * as turf from "@turf/turf"

export function TotalAreaOccupancyCard() {
	const { areas, activeTags, currentFloorPlan, floorPlans } = useSelector(
		state => ({
			areas: state.basePage?.areas,
			activeTags: state.liveData?.activeTags,
			currentFloorPlan: state.profile?.currentFloorPlan,
			floorPlans: state.basePage?.floorPlans
		}),
		shallowEqual
	)

	const [series, setSeries] = useState([])
	const [colors, setColors] = useState([])
	const [currentFloor, setCurrentFloor] = useState(true)

	useEffect(() => {
		if (!areas || !activeTags || !currentFloorPlan) return

		var areasArray = [...areas]

		if (currentFloor) {
			areasArray = areas.filter(area => area.floorPlanId === currentFloorPlan.id)
		}

		var count = []
		areasArray.forEach((area, i) => {
			if (!area.polygon) return
			var polygon = area.polygon.map(coord => {
				return [coord.longitude, coord.latitude]
			})
			polygon.push(polygon[0])
			var poly = turf.polygon([polygon])

			activeTags.forEach(d => {
				if (!d?.gpsData) return
				if (d.floorPlanId === area.floorPlanId) {
					if (!d.person) return
					var pt = turf.point([d.gpsData.longitude, d.gpsData.latitude])
					var inside = turf.booleanPointInPolygon(pt, poly)
					if (inside) {
						count[i] = count[i] ? count[i] + 1 : 1
					}
				}
			})
		})

		var data = []

		areasArray.forEach((area, i) => {
			if (!count && count[i] === 0) return
			const floor = floorPlans.find(val => val.id === area.floorPlanId)
			const name = floor ? `${area.name} (${floor.name})` : area.name

			count[i] && data.push({ x: name, y: count[i], w: area.id, z: area.capacity })
		})

		setSeries([{ data: data }])

		var colorsData = []
		data.forEach(value => {
			const elementsIndex = areasArray.findIndex(area => area.id === value.w)

			var percentage = (value.y * 100) / areasArray[elementsIndex].capacity

			if (percentage >= 100) {
				colorsData.push("#53B04F")
			} else if (percentage >= 75) {
				colorsData.push("#53B04F")
			} else {
				colorsData.push("#53B04F")
			}
		})
		setColors(colorsData)
	}, [areas, activeTags, currentFloor, currentFloorPlan])

	// If no data hide chart.
	if (!series?.[0]?.data || series?.[0]?.data.length === 0) return <div></div>

	return (
		<div className="col-8">
			<Card>
				<CardHeader
					title="People Distribution"
					subtitle="People distribution per area"
					line={false}
					toolbar={
						<>
							<Button
								color={currentFloor ? "primary" : "default"}
								onClick={() => setCurrentFloor(true)}
								text="Current floor"
								style={{
									color: !currentFloor && "#B8BCCA"
								}}
							/>
							<Button
								color={currentFloor ? "default" : "primary"}
								onClick={() => setCurrentFloor(false)}
								text="All floors"
								style={{
									color: currentFloor && "#B8BCCA"
								}}
							/>
						</>
					}
				/>

				<CardBody padding="13px" style={{ height: "380px" }}>
					<TotalAreaOccupancyGraph series={series} colors={colors} />
				</CardBody>
			</Card>
		</div>
	)
}
