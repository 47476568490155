export const COLLECTIONS = {
	CUSTOMERS: "Customers",
	MESSAGES: "Messages",
	SITES: "Sites",
	SENSORS_POSITION: "SensorsPosition"
}

export const MESSAGING = {
	TYPES: {
		MESSAGE: "message",
		ANNOUNCEMENT: "announcement"
	}
}

export const QUERY_CONSTRAINTS = {
	WHERE: {
		TYPE: "type",
		DATE: "date",
		ERASE: "erase",
		UID_TO: "uidTo"
	},
	ORDER_BY: {
		DATE: "date",
		ASC: "asc",
		DESC: "desc",
		CREATED_DATA: "created.date"
	},
	OPERATORS: {
		EQUAL: "==",
		GREATER_THAN: ">",
		GREATER_THAN_OR_EQUAL: ">=",
		LESS_THAN: "<",
		LESS_THAN_OR_EQUAL: "<="
	},
	VALUES: {
		FALSE: false,
		TRUE: true,
		MESSAGES: "messages"
	}
}
