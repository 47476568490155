import { persistReducer, createTransform } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { put, takeLatest /*call*/ } from "redux-saga/effects"
import { /*getUserByToken,*/ getCurrentUser, getUserClaims, getUserPermissions } from "./authCrud"
import * as routerHelpers from "../../../routes/RouterHelpers"
import { auth /*createUserProfileDocument*/ } from "../../../../firebase"
import JSOG from "jsog"

const mockPermissions = {
	permissions: {
		Operations: {
			OperationStatus: { TagListAndUsage: true, MonthlyTagUsage: true },
			MapInfrastructure: { Sensors: true, Network: true },
			SiteSurvey: true,
			Inventory: { Nodes: true, Firmwares: true, Models: true },
			NetworkConfiguration: { Anchors: true, Tags: true },
			Tools: { WriteNfc: true, PrintLabels: true, FindDevices: true }
		},
		Reporting: {
			People: {
				Alarms: true,
				Sensors: true,
				Summary: {
					PeopleDistributionPerFloor: true,
					LocationSummary: true,
					TotalPeopleOnSite: true,
					PeopleDistributionPerArea: true,
					Top5AttendanceTracking: true
				},
				Timesheets: {
					TotalContractedWorkersPerRoleTimesheet: true,
					PeopleDailyTimesheet: true
				},
				Assets: true
			}
		},
		Dashboard: {
			PeopleAndAssets: {
				PeopleDistribution: true,
				TopRowInfo: true,
				ClassesGeneralAttendance: true,
				Map: true,
				AttendanceList: true,
				GeneralAttendance: true
			},
			Sensors: true
		},
		Communication: true,
		Announcements: true,
		Admin: {
			CustomerOnboarding: true,
			DesignStudio: { Routes: true, PointsOfInterest: true, MapOrientation: true, Areas: true },
			PeopleOnboarding: true,
			CustomerOnboarding: true,
			ManageCustomer: {
				Sites: true,
				Configuration: {
					Roles: true,
					Vendors: true,
					Groups: true,
					AssetTypes: true,
					CustomUserRoles: true
				},
				People: true,
				Assets: true,
				PlatformUsers: true
			},
			ManageSite: { People: true, Assets: true }
		},
		AuditTool: { AuditMap: true, TopRowInfo: true, LocationSummary: true, WeeklyReport: true },
		Management: {
			RulesEngine: { CreateRule: true, Roles: true },
			IncidentManagement: {
				AverageTicketResolutionTime: true,
				IncidentReports: true,
				TopRowInfo: true
			}
		}
	},
	roleName: "super",
	default: true,
	rolesToAssign: [2]
}

export const actionTypes = {
	Login: "[Login] Action",
	Logout: "[Logout] Action",
	Register: "[Register] Action",
	UserRequested: "[Request User] Action",
	UserLoaded: "[Load User] Auth API",
	SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
	getUserClaims: "GET_USER_CLAIMS",
	getUserPermissions: "GET_USER_PERMISSIONS",
	setUserPermissions: "SET_USER_PERMISSIONS",
	setDatabase: "SET_DATABASE"
}

const initialAuthState = {
	user: undefined,
	claims: undefined,
	authToken: undefined,
	database: undefined
}

export const JSOGTransform = createTransform(
	(inboundState, key) => JSOG.encode(inboundState),
	(outboundState, key) => JSOG.decode(outboundState)
)

export const reducer = persistReducer(
	{
		storage,
		key: "demo1-auth",
		transforms: [JSOGTransform],
		whitelist: ["user", "claims", "authToken", "permissions", "database"]
	},
	(state = initialAuthState, action) => {
		switch (action.type) {
			case actionTypes.Login: {
				const { authToken } = action.payload

				return { authToken, user: undefined }
			}

			case actionTypes.Register: {
				const { authToken } = action.payload

				return { authToken, user: undefined }
			}

			case actionTypes.Logout: {
				routerHelpers.forgotLastLocation()
				return initialAuthState
			}

			case actionTypes.UserLoaded: {
				const { user } = action.payload

				return { ...state, user }
			}

			case actionTypes.getUserClaims: {
				//console.log("type: getUserClaims, payload: ", action.payload)
				const { claims } = action.payload
				return { ...state, claims }
			}

			case actionTypes.setUserPermissions: {
				const { permissions } = action.payload
				return { ...state, permissions }
			}

			case actionTypes.setDatabase: {
				const { database } = action.payload
				return { ...state, database }
			}

			default:
				return state
		}
	}
)

export const actions = {
	login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
	register: authToken => ({
		type: actionTypes.Register,
		payload: { authToken }
	}),
	logout: () => ({ type: actionTypes.Logout }),
	requestUser: user => ({
		type: actionTypes.UserRequested,
		payload: { user }
	}),
	fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
	signInFailure: error => ({
		type: actionTypes.SIGN_IN_FAILURE,
		payload: error
	}),
	setUserClaims: claims => ({ type: actionTypes.getUserClaims, payload: { claims } }),
	setUserPermissions: permissions => ({
		type: actionTypes.setUserPermissions,
		payload: { permissions }
	}),
	setDatabase: database => ({
		type: actionTypes.setDatabase,
		payload: { database }
	}),
	fetchUserPermissions: () => ({ type: actionTypes.getUserPermissions })
}

export function* saga() {
	yield takeLatest(actionTypes.Login, function* loginSaga() {
		yield put(actions.requestUser())
	})

	yield takeLatest(actionTypes.Register, function* registerSaga() {
		yield put(actions.requestUser())
	})

	yield takeLatest(actionTypes.UserRequested, userRequested)

	yield takeLatest(actionTypes.Logout, logoutSaga)
}

function* logoutSaga() {
	try {
		yield auth.signOut()
		console.log("logout success")
	} catch (error) {
		console.log("sign out fail", error)
	}
}

function* userRequested() {
	const user = yield getCurrentUser()

	const userData = {
		username: user.displayName,
		email: user.email,
		phone: user.phoneNumber,
		pic: user.photoURL,
		accessToken: user.uid,
		refreshToken: user.refreshToken,
		metadata: user.metadata,
		metadata: user.emailVerified,
		id: user.uid,
		fullname: user.displayName,
		tenantId: user.tenantId
	}

	const userClaims = yield getUserClaims(user)
	const profileLvl = getProfileLvl(userClaims)
	yield put(actions.setUserClaims({ ...userClaims, profileLvl }))

	const permissions = yield getUserPermissions(userClaims)
	yield put(actions.setUserPermissions(permissions ? permissions : mockPermissions))

	yield put(actions.fulfillUser(userData))
}

//dont delete until you make sure new user roles are working
// export function getProfileLvl(customClaims) {
// 	const profile = customClaims.isUser
// 		? 1
// 		: customClaims?.isAdmin
// 		? 2
// 		: customClaims?.isMSP
// 		? 3
// 		: customClaims?.isSuper
// 		? 4
// 		: 0
// 	return profile
// }

export function getProfileLvl(customClaims) {
	//setting isUser and isAdmin with same priority
	const profile =
		customClaims?.userRole && customClaims?.customerId
			? 1 //isUser if has access role and customerId (single)
			: customClaims?.userRole && customClaims?.sites?.length > 0
			? 2 //isAdmin if has access role and customerId (single)
			: !customClaims?.userRole && customClaims?.customerIds?.length > 0
			? 3 //isMSP if doesn't have a role (can see it all) but limit customers access
			: !customClaims?.userRole && !customClaims?.customerId && !customClaims?.customerIds
			? 4 //isSuper if no role and no customer restriction
			: 0 //in case of error
	return profile
}
