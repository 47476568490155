import React, { useState, useEffect } from "react"

import { useHistory, useLocation } from "react-router-dom"
import NestedDrawer from "../_partials/NestedDrawer"
import { useStylesForSidebar } from "../_partials/StylesOverride"
import MainDrawer from "../_partials/MainDrawer"

const Sidebar = ({ options, hideDrawer, handleHideDrawer, matches }) => {
	const classes = useStylesForSidebar()
	const history = useHistory()
	const location = useLocation()

	const [isNestedDrawerOpen, setNestedDrawerOpen] = useState(false)
	const [currentNestedOptions, setCurrentNestedOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState(null)
	const [selectedNestedOption, setSelectedNestedOption] = useState(null)
	const [selectedMainOption, setSelectedMainOption] = useState(null)
	const [mainDrawerOpened, setMainDrawerOpened] = useState(true)

	useEffect(() => {
		// Set the selected option based on the current URL
		const currentPath = location.pathname

		const findSelectedOption = options => {
			for (let option of options) {
				if (option.label.redirectTo === currentPath) {
					setSelectedMainOption(option.label.redirectTo)
					return option.label.redirectTo
				}

				if (option.nestedOptions) {
					for (let nestedOption of option.nestedOptions) {
						if (nestedOption.redirectTo === currentPath) {
							setSelectedMainOption(option.label.redirectTo)
							return nestedOption.redirectTo
						}

						if (nestedOption.nestedOptions) {
							const deepNestedOption = nestedOption.nestedOptions.find(
								deepOption => deepOption.redirectTo === currentPath
							)
							if (deepNestedOption) {
								setSelectedMainOption(option.label.redirectTo)
								return deepNestedOption.redirectTo
							}
						}
					}
				}
			}
			return null
		}

		const selected = findSelectedOption(options)
		setSelectedOption(selected)
	}, [location, options, isNestedDrawerOpen])

	return (
		<>
			<MainDrawer
				selectedMainOption={selectedMainOption}
				setSelectedMainOption={setSelectedMainOption}
				setSelectedNestedOption={setSelectedNestedOption}
				setCurrentNestedOptions={setCurrentNestedOptions}
				setNestedDrawerOpen={setNestedDrawerOpen}
				mainDrawerOpened={mainDrawerOpened}
				setMainDrawerOpened={setMainDrawerOpened}
				options={options}
				hideDrawer={hideDrawer}
				handleHideDrawer={handleHideDrawer}
				matches={matches}
				history={history}
				classes={classes}
			/>
			<NestedDrawer
				isNestedDrawerOpen={isNestedDrawerOpen}
				setNestedDrawerOpen={setNestedDrawerOpen}
				currentNestedOptions={currentNestedOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				selectedNestedOption={selectedNestedOption}
				mainDrawerOpened={mainDrawerOpened}
				handleHideDrawer={handleHideDrawer}
				matches={matches}
				history={history}
				classes={classes}
			/>
		</>
	)
}

export default Sidebar
